import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const utills = require('../../utilities/src/utills')
interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  errors: {
    Name: string,
    CompanyName: string,
    Email: string,
    PhoneNumber: string,
    comments: string,
  },
  hasSuccessError: HasSuccessErrorType;
  currentLanguage:'en'|'ar';
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      currentLanguage:configJSON.EN,
      isVisible: false,
      errors: {
        Name: "",
        CompanyName: "",
        Email: "",
        PhoneNumber: "",
        comments: ""
      },
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.setState({ contactUsList: responseJson.data });
        }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.setState({hasSuccessError: {
            isOpen: true,
            isSeverity: "success",
            isMessage: "Data submitted successfully"
          },
        })
          
          this.setState({
            name:"",
            companyName: "",
            email:"",
            phoneNumber:"",
            comments:""
          })
          this.handleServiceContactNavigate();
        }
      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.addContactApiCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
   
  handleCloseSnack = () => {
    this.setState({
        hasSuccessError: {
            isOpen: false,
            isSeverity: "success",
            isMessage: ""
        }
    })
};
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
      this.resetError("Name");
    },
  };

  txtCompanyNameProps = {
    onChangeText: (text: string) => {
      this.setState({ companyName: text });

      //@ts-ignore
      this.txtCompanyNameProps.value = text;
      this.resetError("CompanyName");
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      this.resetError("Email");

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      const inputValue = text.replace(/[a-zA-Z@!#$%^&*()_+={}[\]:;<>,.?~\\/-]/g,'');
      this.setState({ phoneNumber: inputValue });
      this.resetError("PhoneNumber");

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });
      this.resetError("comments");

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  validatePhoneNumber = (phoneNumber: string) => /^\d{10}$/.test(phoneNumber);
  validationName = (name: string) => /^[^\d][a-zA-Z\d\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\~-]{2,}$/.test(name);

  getArabicName = (name:string)=>{
    switch(name){
      case 'Name': return 'اسم';
      case 'CompanyName':return 'اسم الشركة';
      case 'Email':return 'بريد إلكتروني';
      case "PhoneNumber":return "رقم التليفون";
      default : return ''
    }
  }

  setError = (field: string, msg: string = "") => {
    const {currentLanguage} = this.state;
    const isEnglish = currentLanguage === 'en';
    const endMsg = isEnglish ? 'is required' :'مطلوب';
    const ErrorField = isEnglish?field: this.getArabicName(field)
    let errorMessage = msg ? msg : `${ErrorField} ${endMsg}`;
  
    const formattedFieldName = ErrorField.replace(/([a-z])([A-Z])/g, '$1 $2');
    errorMessage = `${formattedFieldName} ${endMsg}`;
  
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: msg ? msg : errorMessage,
      },
    }));
  };

  resetError (field: string) {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: '',
      },
    }));
  }

  checkInputField = () => {
    let hasErrors = false;
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setError("Name");
       hasErrors = true;
    }
  
    if (this.isStringNullOrBlank(this.state.companyName)) {
      this.setError("CompanyName");
       hasErrors = true;
  
    }
   
    if (this.isStringNullOrBlank(this.state.email)) {
      this.setError("Email");
       hasErrors = true;
    }
    if (this.isStringNullOrBlank(this.state.phoneNumber)) {
      this.setError("PhoneNumber");
       hasErrors = true;
    }

    return hasErrors;
  }
  
  checkInputError = () => {
    let hasErrors = false;
  
    if (!this.isStringNullOrBlank(this.state.email)) {
      if (!this.isValidEmail(this.state.email)) {
        this.setError("Email", configJSON.errorEmailNotValid);
        hasErrors = true;
      }
    }
   
    if (!this.isStringNullOrBlank(this.state.phoneNumber)) {
      if (!this.validatePhoneNumber(this.state.phoneNumber)) {
        this.setError("PhoneNumber", configJSON.phoneNumberError);
        hasErrors = true;
      }
    }

    if (!this.isStringNullOrBlank(this.state.name)) {
      if (!this.validationName(this.state.name)) {
        this.setError("Name", configJSON.nameError);
        hasErrors = true;
      }
    }
     
    return hasErrors;
  
  }
 

  addQueryApi = () => {

    let hasErrors1 = this.checkInputField();
    let hasErrors2 = this.checkInputError();
    if(!hasErrors1 && !hasErrors2){  
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          company_name: this.state.companyName.trim(),
          message: this.state.comments.trim()
        },
      };
      const header = {
        "Content-Type": configJSON.contactUsApiContentType
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };
  handleServiceContactNavigate = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Home"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  } 
  // Customizable Area End
}
