import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
const utills = require('../../utilities/src/utills');
const languageTranslation = require('../../languagesupport/src/LanguageData/Subscription.json');

interface ValidResponse {
  status: number;
  message: string;
  error: string;
}

export interface Subscription {
  id: string;
  price: string;
  cycle: string;
  name: string;
  description: Array<string>;
  services: Array<string>;
};

interface ErrorItem {
  cardNumErr: string;
  cardHoldErr: string;
  expDateErr: string;
  cvvErr: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  currentLanguage: string;
  showModal: boolean;
  cardNumber: string;
  checkPaySubmit: boolean;
  cardHolderName: string;
  expDate: string;
  cvvNumber: string;
  autoRenewal: boolean;
  error: ErrorItem;
  planSelected: Subscription;
  showSnackbar: "close" | "success" | "error";
  subscriptionMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  selectSubscriptionCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      currentLanguage: configJSON.EN,
      cardNumber: "",
      showModal: false,
      cardHolderName: "",
      expDate: "",
      autoRenewal: false,
      cvvNumber: "",
      checkPaySubmit: false,
      error: {
        cardNumErr: "",
        cardHoldErr: "",
        expDateErr: "",
        cvvErr: ""
      },
      planSelected: {
        id: "",
        price: "",
        cycle: "",
        name: "",
        description: [],
        services: []
      },
      showSnackbar: "close",
      subscriptionMessage: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsList();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(!responseJson.errors) {
          this.apiSuccessCallBack(apiRequestCallId, responseJson);
        } else {
          this.apiFailureCallBack(apiRequestCallId);
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start

  apiSuccessCallBack = (apiRequestCallId: string, responseJson: ValidResponse) => {
    if (apiRequestCallId === this.getListCallId) {
      this.setState({
        subscriptions: languageTranslation[this.state.currentLanguage].SubscriptionData
      });
    };
    if (apiRequestCallId === this.selectSubscriptionCallId) {            
      this.handelSubsSuccessResponse(responseJson);
    }
  };

  apiFailureCallBack = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.selectSubscriptionCallId) {
      this.handelSubsErrorResponse();
    }
  };

  selectPlanApi = async (planId: number) => {
    const header = {
      token: await getStorageData("accessToken"),
      "Content-Type": configJSON.subscriptionApiContentType
    };
    const body = {
      "id": planId
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.selectSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionPlanEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handelSubsSuccessResponse = (responseJson: {status: number, message: string, error: string}) => {
    if(responseJson.message) {
      this.setState({
        showSnackbar: "success",
        showModal: false,
        checkPaySubmit: false,
        cardHolderName: "",
        cardNumber: "",
        expDate: "",
        cvvNumber: "",
        subscriptionMessage: responseJson.message
      });
    } else {
      if(responseJson.error) {
        this.setState({
          showSnackbar: "error",
          showModal: false,
          checkPaySubmit: false,
          cardHolderName: "",
          cardNumber: "",
          expDate: "",
          cvvNumber: "",
          subscriptionMessage: responseJson.error
        });
      }
    }
  };

  handelSubsErrorResponse = () => {
    this.setState({
      showSnackbar: "error",
      showModal: false,
      checkPaySubmit: false,
      cardHolderName: "",
      cardNumber: "",
      expDate: "",
      cvvNumber: "",
      subscriptionMessage: languageTranslation[this.state.currentLanguage].somethingWentWrong
    });
  }

  handleCardNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/\D/g, '');
    const limitedValue = sanitizedValue.slice(0, 16);
    const formattedValue = limitedValue.replace(/(\d{4})(?=\d)/g, '$1 ');
    this.setState({cardNumber: formattedValue}, () => this.handleErrorCheck());
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      checkPaySubmit: false,
      cardHolderName: "",
      cardNumber: "",
      expDate: "",
      cvvNumber: ""
    });
  };

  handleCardHolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({cardHolderName: event.target.value}, () => this.handleErrorCheck());
  };

  handleValidYear = (monthGet: string, yearGet: string) => {
    const getCurrentDate = new Date();
    const getCurrentYear = getCurrentDate.getFullYear();
    const getCurrentMonth = getCurrentDate.getMonth()+1;
    let year = yearGet;
    let month = monthGet;
    if (parseInt(yearGet, 10) < getCurrentYear) {
      year = getCurrentYear.toString();
      if(parseInt(monthGet, 10) > getCurrentMonth) {
        if(getCurrentMonth < 10) {
          month = `0${getCurrentMonth.toString()}`;
        } else {
          month = getCurrentMonth.toString();
        }
      }
    }
    return [month, year];
  };

  handleInputDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let getValue = event.target.value;
    getValue = getValue.replace(/\D/g, '');
    if (getValue.length > 6) {
      getValue = getValue.slice(0, 6);
    }
    if (getValue.length > 2 && !getValue.includes('/')) {
      getValue = getValue.slice(0, 2) + '/' + getValue.slice(2);
    }
    let parts = getValue.split('/');
    if (getValue.length > 2) {
      const month = parseInt(parts[0], 10);
      if (month > 12) {
        parts[0] = '12';
      } else if (month < 1) {
        parts[0] = '01';
      }
      parts[0] = parts[0].slice(0, 2);
    }
    if (parts[1] && parts[1].length === 4) {
      parts = this.handleValidYear(parts[0], parts[1]);
    }
    getValue = parts.join('/');
    this.setState({ expDate: getValue }, () => this.handleErrorCheck());
  };

  handleErrorCheck  = () => {
    let cardNumErr = "", cardHoldErr = "", expDateErr = "", cvvErr = "";
    const { cardNumber, cardHolderName, expDate, cvvNumber, currentLanguage } = this.state;
    const { errors } = languageTranslation[currentLanguage];
    if(cardNumber.length < 19) {
      cardNumErr = errors.cardNumErr;
    }
    if(cardHolderName.length < 3) {
      cardHoldErr = errors.cardHoldErr;
    } 
    if(expDate.length < 7) {
      expDateErr = errors.expDateErr;
    } 
    if(cvvNumber.length < 3) {
      cvvErr = errors.cvvErr;
    }
    this.setState({
      error: { cardNumErr, cardHoldErr, expDateErr, cvvErr }
    });
    return { cardNumErr, cardHoldErr, expDateErr, cvvErr };
  };

  handleCvvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/\D/g, '');
    const limitedValue = sanitizedValue.slice(0, 4);
    this.setState({cvvNumber: limitedValue}, () => this.handleErrorCheck());
  };

  handleMakePayment = () => {
    const error  = this.handleErrorCheck();
    this.setState({
      checkPaySubmit: true
    });
    let checkErrors = Object.values(error).every((value) =>
      value === undefined || value.length === 0
    );
    if( checkErrors ) {
      this.selectPlanApi(+this.state.planSelected.id);
    }
  };

  handleAutoRenew = () => {
    this.setState({autoRenewal: !this.state.autoRenewal});
  };

  handleSelectPlan = (index: number) => {
    this.setState({
      planSelected: this.state.subscriptions[index],
      showModal: true
    })
  };

  handleSnackbarClose = () => {
    this.setState({
      showSnackbar: "close",
      subscriptionMessage: ""
    });
  };

  engArabicNumeral = (value: string): string => {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    if(this.state.currentLanguage === "ar") {
      return String(value).replace(/\d/g, (d) => arabicNumerals[parseInt(d)]);
    } else {
      return value;
    }
  };

  // Customizable Area End
}
