import React, { Component } from "react";
const configJSON = require("../../config");

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    styled,
    Divider,
    IconButton,
    Drawer,
    Typography,
    Modal,
    Button
} from "@material-ui/core";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AppsIcon from "@material-ui/icons/Apps";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PortraitOutlinedIcon from "@material-ui/icons/PortraitOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { offerIcon, newLogoSvg } from "../../assets";
const utills = require('../../../../blocks/utilities/src/utills');
import { getStorageData } from "framework/src/Utilities";
import { AdminNavbar } from "../../../../blocks/adminconsole2/src/AdminNavbar.web"

interface DrawerItem {
    text: string,
    icon: React.ReactNode,
    route: string
}
interface Props {
    handleClick: (route: string) => void;
    breadScrumArray: string[];
    pageName: string;
    userType: string;
    setMargin: boolean;
    setBackground: boolean;
    navigation : any
}

interface S {
    open: boolean;
    currentLanguage: 'ar' | 'en';
    drawerList: DrawerItem[] | null;
    isHover: number | null;
    showModal: boolean;
    userType: string | null;
    userSubType: string | null;
}

interface SS {
    id: any;
}

export default class UserProfileDrawer extends Component<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            currentLanguage: 'en',
            drawerList: null,
            isHover: null,
            showModal: false,
            userType: "debt_owner",
            userSubType: "bank"
        };
    }

    static defaultProps = {
        breadScrumArray: [],
        userType: "consumer",
        setMargin: false,
        setBackground: false,
        navigation : ""
    };

    async componentDidMount() {
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        const userType = await getStorageData("userType");
        const userSubType = await getStorageData("userSubType");
        this.setState({
            currentLanguage: selectedLanguage,
            userType: userType,
            userSubType: userSubType
        });
    };

    handleDrawerList = () => {
        let {currentLanguage, userType} = this.state;
        const languageData = configJSON.drawerListLanuage[currentLanguage];
        let array: DrawerItem[];
        if(!userType){
            array = [
                {   text: languageData.dashboard, 
                    icon: <AppsIcon />,
                    route: "dashboard"
                },
                {
                    text: languageData.notification,
                    icon: <NotificationsNoneOutlinedIcon />,
                    route: "dashboardNotification"
                },
                {   text: languageData.Usermanagement, 
                    icon: <PermIdentityIcon />, 
                    route: "dashboardUserManagement" 
                },
                {
                    text: languageData.VerificationRequests,
                    icon: <DescriptionOutlinedIcon />,
                    route: "dashboardVerificationRequest"
                },
                {
                    text: languageData.Listings,
                    icon: <FormatListBulletedOutlinedIcon />,
                    route: "dashboardListing"
                },
                {
                    text: languageData.offers,
                    icon: <img width="24px" src={offerIcon} />,
                    route: "dashboardAdminOffers"
                },
                {
                    text: languageData.Collections,
                    icon: <FormatListBulletedOutlinedIcon />,
                    route: "dashboardCollections"
                },
                {
                    text: languageData.Documents,
                    icon: <DescriptionOutlinedIcon />,
                    route: "Documentation"
                },
                {
                    text: languageData.AuditLogs,
                    icon: <QueryBuilderRoundedIcon/>,
                    route: "dashboardAuditlog"
                },
                {   text: languageData.Plantransactions, 
                    icon: <CreditCardIcon />,
                    route: "PlanTransactions"
                },
                {   text: languageData.logOut, 
                    icon: <ExitToAppIcon />, 
                    route: "logOut" 
                }
            ]
        } else {
            array = [
                {   text: languageData.dashboard, 
                    icon: <AppsIcon />, 
                    route: "UserDashboard"
                },
                {   text: languageData.accountDetails, 
                    icon: <PermIdentityIcon />, 
                    route: "UserProfile" 
                },
                {
                    text: languageData.businessProfile,
                    icon: <PortraitOutlinedIcon />,
                    route: "BusinessProfile"
                },
                {
                    text: languageData.notification,
                    icon: <NotificationsNoneOutlinedIcon />,
                    route: "UserNotification"
                },
                {
                    text: languageData.verificationRequest,
                    icon: <DescriptionOutlinedIcon />,
                    route: "UserRequests"
                },
                {
                    text: languageData.myListings,
                    icon: <FormatListBulletedOutlinedIcon />,
                    route: "UserMyListing"
                },
                {
                    text: languageData.offers,
                    icon: <img width="24px" src={offerIcon} />,
                    route: "UserMyOffers"
                },
                {   text: languageData.plan, 
                    icon: <CreditCardIcon />, 
                    route: "UserPlan"
                },
                {   text: languageData.logOut, 
                    icon: <ExitToAppIcon />, 
                    route: "logOut" 
                }
            ]
        }
        return array;
    }

    toggleDrawer = (isOpen: boolean) => {
        this.setState({ open: isOpen });
    };

    setIsHovered = (value: number | null) => {
        this.setState({isHover: value});
    };

    handleListItemStyle = () => {
        if(this.state.currentLanguage === "en"){
            return { paddingLeft :"35px" }
        } else {
            return { paddingRight: "35px" }
        }
    };

    handleListClick = (route: string) => {
        if(route === "logOut") {
            this.setState({ showModal: true });
        } else {
            this.props.handleClick(route);
        }
    };

    handleModalClose = () => {
        this.setState({ showModal: false });
    };

    list = () => {
        const { currentLanguage, isHover, userSubType } = this.state;
        const { pageName } = this.props;
        return (
            <div
                data-test-id="drawerListTestID"
                role="presentation"
                onClick={() => this.toggleDrawer(false)}
                style={{ maxWidth: "300px", minWidth: "252px", paddingTop: "40px" }}
            >
                <List>
                    {this.handleDrawerList() &&
                    this.handleDrawerList().map((item, index) => (
                        <>
                            { (
                                (userSubType === "individual" || 
                                userSubType === "investor") && 
                                item.route === "UserRequests") ? 
                                null:
                                <CustomListItem
                                    data-test-id={`drawerButtonTestID${index}`}
                                    key={index}
                                    button
                                    style={{
                                        marginTop: item.route==="logOut" ? "30px" : "0px", 
                                        backgroundColor:pageName===item.route?"#EFF6FF":"white",
                                        borderRight:((pageName===item.route || isHover == index) && currentLanguage === "en")? "2px solid #1D4ED8":"none",
                                        borderLeft:((pageName===item.route || isHover == index) && currentLanguage === "ar")? "2px solid #1D4ED8":"none",
                                        ...this.handleListItemStyle()
                                    }}
                                    onClick={() => this.handleListClick(item.route)}
                                    onMouseEnter={() => this.setIsHovered(index)}
                                    onMouseLeave={() => this.setIsHovered(null)}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText 
                                        primary={item.text} 
                                        style={
                                            currentLanguage === "en"? 
                                            webStyle.listItemEnStyle:
                                            webStyle.listItemArStyle
                                        }
                                    />
                                </CustomListItem>
                            }
                        </>
                    ))}
                </List>
            </div>
        );
    };

    renderBreadScrum = () => {
        const { breadScrumArray, handleClick} = this.props;
        return (
            <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {breadScrumArray.map((item, index) => (
              <React.Fragment key={index}>
                <Typography
                    data-test-id={`breadScrumItem${index}`}
                    style={{ color: breadScrumArray.length - 1 === index ? "#0F172A": "#475569", fontSize: "14px", cursor: "pointer" }}
                    onClick={() => handleClick(`breadScrum${index}`)}
                >
                  {item}
                </Typography>
                {breadScrumArray.length - 1 === index ? null : (
                    <>
                        {this.state.currentLanguage === "en"?
                            <ArrowForwardIosRoundedIcon
                                style={{ color: "#64748B", width: "12px" }}
                            />:
                            <ArrowBackIosRoundedIcon
                                style={{ color: "#64748B", width: "12px" }}
                            />
                        }
                    </>
                )}
              </React.Fragment>
            ))}
          </Box>
        );
    };

    renderModal = () => {
        const { currentLanguage } = this.state;
        const languageData = configJSON.drawerListLanuage[currentLanguage];
        const dir = currentLanguage === "ar"? "rtl": "ltr";
        return (
            <Modal 
                data-test-id = "modalTestID"
                open={this.state.showModal}
                onClose={this.handleModalClose}
            >
                <Box style={webStyle.modalMainBox}>
                    <Box style={webStyle.modalBodyBox}>
                        <Box dir={dir} style={webStyle.modHeadingBox}>
                            <Typography style={webStyle.modHeading}>
                                {languageData.logoutConfirmText}
                            </Typography>
                            <IconButton onClick={this.handleModalClose}>
                                <CloseRoundedIcon style={{color: "#0F172A"}}/>
                            </IconButton>
                        </Box>
                        <ModButtonBox style={webStyle.modButtonBox}>
                            <ResetButton onClick={this.handleModalClose}>{languageData.Cancel}</ResetButton>
                            <CreateButton 
                                data-test-id="modalLogoutButtonTestID"
                                onClick={() => this.props.handleClick("logOut")}
                            >
                                {languageData.Logout}
                            </CreateButton>
                        </ModButtonBox>
                    </Box>
                </Box>
            </Modal>
        );
    };

    render() {
        const { currentLanguage, userType } = this.state;
        const { breadScrumArray, setMargin, setBackground } = this.props;
        const dir = currentLanguage === "ar"? "rtl": "ltr";
        return (
            <MainWrapperBox style={{background:(!userType && !setBackground)? "#F8FAFC": "white" }}>
                <FullListWrapper style={{background: "white"}}>
                    {!userType &&
                        <img 
                            src={newLogoSvg} 
                            alt="compony_logo" 
                            style={{
                                width: "160px", 
                                margin: currentLanguage === "en"? "40px -10px 0 40px": "40px 40px 0px 0px",
                                cursor: "pointer"
                            }}
                            onClick={() => this.props.handleClick("Home")}
                        />
                    }
                    {this.list()}
                </FullListWrapper>
                <Divider
                    orientation="vertical"
                    flexItem
                    style={{ borderColor: "#E2E8F0", borderWidth: "1px" }}
                />
                <Box style={{width : "100%"}}>
                    {
                      !userType &&
                <AdminNavbar navigation={this.props.navigation}  id="AdminNavbar" title="User Management" />
                    }
                <CustomMainBox 
                    style={{
                        ...webStyle.mainBox,
                        marginTop:breadScrumArray.length===0?"50px":"0px",
                        marginRight: ((userType || setMargin) && currentLanguage === "en")? "65px": "0px",
                        marginLeft: ((userType || setMargin) && currentLanguage === "ar")? "65px": "0px",
                    }}
                >


                    <Box
                        style={{
                        marginTop: (userType)? "30px": "10px",
                            marginBottom: "25px",
                            display: breadScrumArray.length===0? "none":"block"
                        }}
                    >
                        {this.renderBreadScrum()}
                    </Box>
                    <MenuButtonWrapper>
                        <IconButton
                            data-test-id="openDrawerButtonTestID"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => this.toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </MenuButtonWrapper>
                    {this.props.children}
                </CustomMainBox>
                </Box>
                <Drawer
                    data-test-id="drawerTestID"
                    anchor={currentLanguage === "en"? "left": "right"}
                    open={this.state.open}
                    onClose={() => this.toggleDrawer(false)}
                    dir = {dir}
                >
                    {(!userType && this.state.open) &&
                        <img 
                            src={newLogoSvg} alt="compony_logo" 
                            style={{
                                width: "120px", 
                                margin: currentLanguage === "en"? "40px 0 0 40px": "40px 90px 0px 0px",
                                cursor: "pointer"
                            }}
                            onClick={() => this.props.handleClick("Home")}
                        />
                    }
                    {this.state.open && this.list()}
                </Drawer>
                {this.renderModal()}
            </MainWrapperBox>
        );
    }
}

const CreateButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    width: "248px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});

const ResetButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "56px",
    width: "248px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});

const MenuButtonWrapper = styled(Box)({
    marginTop: "-25px",
    "@media(min-width: 991px)": {
        display: "none"
    }
});

const MainWrapperBox = styled(Box)({
    display: "flex",
    "@media(max-width: 990px)": {
        display: "block",
        "& .MuiDivider-root": {
            display: "none"
        }
    }
});

const ModButtonBox = styled(Box)({
    "@media(max-width: 580px)": {
        flexDirection: "column",
        "& .MuiButton-root": {
            width: "100%"
        }
    }
});

const FullListWrapper = styled(Box)({
    width: "100%",
    maxWidth: "300px",
    "@media(max-width: 990px)": {
        display: "none"
    }
});

const CustomMainBox = styled(Box)({
    padding: "0px 60px 50px 50px",
    "@media(max-width: 1240px)": {
        marginRight: "0 !important",
        marginLeft: "0 !important"
    },
    "@media(max-width: 655px)": {
        padding: "0px 15px"
    }
});

const CustomListItem = styled(ListItem)({
    paddingLeft: "35px",
    cursor: "pointer",
    "& .MuiListItemIcon-root": {
        color: "#0F172A"
    },
    "& .MuiListItemText-root": {
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 500
    },
    "&:hover": {
        backgroundColor: "#EFF6FF !important"
    }
});

const webStyle = {
    listItemArStyle: {
        textAlign: "right"
    } as React.CSSProperties,
    modalMainBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
    modalBodyBox: {
        background: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px 4px #00000008",
        width: "560px",
        margin: "auto 10px"
    },
    modHeadingBox: {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 17px 20px 35px",
        borderBottom: "1px solid #E2E8F0",
        alignItems: "baseline"
    },
    modHeading: {
        fontSize: "20px",
        color: "#0F172A",
        fontWeight: 700
    },
    modButtonBox: {
        display: "flex",
        gap: "20px",
        padding: "25px"
    },
    listItemEnStyle: {
        textAlign: "left"
    } as React.CSSProperties,
    headerAvatarBox: {
        display: "flex",
        alignItems: "center",
        gap: "45px"
    },
    avatarStyle: {
        width: "48px",
        height: "48px",
        boxSizing: "border-box",
        border: "3px solid #DBEAFE"
    } as React.CSSProperties,
    mainBox: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        flexGrow: 1
    } as React.CSSProperties,
    searchWrapperBox: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "23px",
        maxWidth: "520px"
    }
}