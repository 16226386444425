import React from "react"
import { Card, Box, IconButton, Typography, styled } from "@material-ui/core"

const dotImage = require("./image_.png")
const defaultDocImage = require("./default_doc_image.png")


const InfoBox = styled(Box)({
    marginLeft: "8px",
    minWidth: "100px",
    maxWidth: "300px",
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 500px)": {
        maxWidth: "150px"
    }
})

const DocView = ({ imageUrl="", name="", size="" }) => {
    return (
        <Card style={styles.container}>
            <Box style={styles.imageContainer}>
                <img style={styles.image} src={imageUrl || defaultDocImage} alt="" />
            </Box>
            <InfoBox>
                <Typography style={styles.name}>
                {name}
                </Typography>
                <Typography style={styles.size}>
                {size}
                </Typography>
            </InfoBox>
            <IconButton style={styles.dotButton}>
                <img src={dotImage} alt="Icon" />
            </IconButton>
        </Card>
    )
}

export default DocView

const styles : any = {
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxSizing: "border-box"
    },
    imageContainer: {
        height: "56px",
        width: "56px",
        borderRadius: "8px",
        overflow: "hidden",
        flexShrink: 0
    },
    image: {
        height: "100%",
        width: "100%"
    },
    name: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    size: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#64748B",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    dotButton: {
        marginLeft: "auto",
        display: "flex",
        height: "50px",
        width: "50px",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0
    }
}