import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/MyOffers.json');
// Customizable Area End

// Customizable Area Start

interface AdminOffer {
    id: string;
    type: string;
    attributes: {
        id: number;
        offer_type: string;
        offer_amount: number;
        status: string;
        offer_date: string;
        admin_status: string;
        buyer_name: string;
        listing_id: number;
        listing_type: string;
    };
};

interface UserOfferAttributes {
    id: number;
    listing_id: number;
    offer_type: string;
    offer_amount: number;
    offer_pitch: string;
    status: string | null;
    admin_status: string;
    provider_id: number;
    consumer_id: number;
    Company: string;
    Debtor: string;
    resource: {
        data :{
            id: string;
        }
    };
    "Debt Owner Name": string;
    "Debt Owner Image": Document | null;
    buyer_name: string;
    "Buyer": string;
    "Buyer Image": Document | null;
    provider: {
        full_name: string;
    };
    consumer: {
        full_name: string;
    };
    offer_date: string;
};

interface OfferDetailShow {
    id: string;
    type: string;
    attributes: {
        offer_type: string;
        offer_amount: number;
        offer_pitch: string;
        status: string;
        admin_status: string;
        "Debt Owner Name":  string;
        "Debt Owner Image":  Document | null;
        Buyer: string;
        "Buyer Image": Document | null;
        resource: {
            data: {
                id: string;
                attributes: {
                    id: string;
                    total_number_of_debts: number;
                    total_amount_of_debts: number;
                    total_judgements: number;
                    last_collectors_contact: string;
                    last_creditors_contact: string;
                    total_months_as_costomer: number;
                    pas_payment_made: boolean;
                    month_average_paytime: number;
                    account_id: number;
                    created_at: string;
                    portfolio_size: string;
                    listing_title: string;
                    avg_balance_per_account: string;
                    account_count: string;
                    type_of_dept: string;
                    age_of_dept: string;
                    current_recovery_status: string;
                    supporting_documents: (Document | null)[][];
                    other_documents: (Document | null)[][];
                }
            }
        };
        provider: {
            full_name: string;
            email: string;
            full_phone_number: string;
            country: string;
            state: string;
            city: string;
            zipcode: string
        };
        consumer: {
            full_name: string;
            email: string;
            full_phone_number: string;
            country: string;
            state: string;
            city: string;
            zipcode: string
        };
    };
};

interface UserOfferData {
    id: string;
    type: string;
    attributes: UserOfferAttributes;
};

interface BusinessDealItem {
    id: string;
    type: string;
    attributes: {
        date_added: string;
        name: string;
        status: string;
        quantity: string;
        description: string;
        terms: string;
        price: string;
        category_name: string | null;
        sub_category_name: string | null;
        documents: DocumentFiles[];
        debt_owner_name: DebtOwner;
    };
};

interface DocumentFiles {
    name: string | null
    url: string | null;
    size: string | null;
};
  
interface DebtOwner {
    data: {
      id: string;
      type: string;
      attributes: {
        full_name: string | null;
        full_phone_number: string;
        country_code: string | null;
        email: string;
        user_type: string;
        sub_type: string;
        city: string;
        state: string;
        country: string;
        zipcode: string;
        bank_name: string | null;
        agency_name: string | null;
        service_provider_name: string | null;
        email_otp_verify: boolean;
        commercial_number: string | null;
        license_number: string | null;
        signup_status: string;
        profile_image: string | null;
        images: {
          address_proof_image: Document;
          id_proof_image: Document;
        } | null;
      };
    };
};  

const offerDetailShowDummyValue = {
    id: "12345",
    type: "user_offer",
    attributes: {
        offer_type: "Service",
        offer_amount: 2500.0,
        offer_pitch: "Our firm, Apex Financial Solutions, has over.",
        status: "pending",
        admin_status: "",
        "Debt Owner Name":  "James Carter",
        "Debt Owner Image":  null,
        Buyer: "",
        "Buyer Image": null,
        resource: {
            data: {
                id: "12345",
                attributes: {
                    id: "12345",
                    total_number_of_debts: 0,
                    total_amount_of_debts: 0,
                    total_judgements: 0,
                    last_collectors_contact: "26092024",
                    last_creditors_contact: "26092024",
                    total_months_as_costomer: 0,
                    pas_payment_made: false,
                    month_average_paytime: 0,
                    account_id: 0,
                    created_at: "2024-09-23T08:48:45.743Z",
                    portfolio_size: "2500",
                    listing_title: "Medical Debt Package",
                    avg_balance_per_account: "2500",
                    account_count: "3",
                    type_of_dept: "credit card",
                    age_of_dept: "2",
                    current_recovery_status: "active",
                    supporting_documents: [[null]],
                    other_documents: [[null]]
                }
            }
        },
        provider: {
            full_name: "James Carter",
            email: "james.carter@example.com",
            full_phone_number: "5678901234",
            country: "United State",
            state: "Florida",
            city: "Miami",
            zipcode: "33001"
        },
        consumer: {
            full_name: "James Carter",
            email: "james.carter@example.com",
            full_phone_number: "5678901234",
            country: "United State",
            state: "Florida",
            city: "Miami",
            zipcode: "33001"
        }
    }
};

const businessDealDetail = {
    id: "",
    type: "",
    attributes: {
        name: "",
        date_added: "",
        status: "",
        quantity: "",
        description: "",
        terms: "",
        price: "",
        category_name: null,
        sub_category_name: null,
        documents: [],
        debt_owner_name: {
            data: {
                id: "",
                type: "",
                attributes: {
                full_name: null,
                full_phone_number: "",
                country_code: null,
                email: "",
                user_type: "",
                sub_type: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",                        
                bank_name: "",
                agency_name: null,
                service_provider_name: null,
                email_otp_verify: true,
                commercial_number: "",
                license_number: "",
                signup_status: "",
                profile_image: null,
                images: null
                }
            }
        }
    }
};

const businewwDealList = [
    ["#669059", "1", "3,000,000", "16 Dec 2022", "Paid"],
    ["#669023", "2", "1,166,667", "21 Mar 2022", "Due"],
    ["#234143", "3", "1,166,667", "03 Dec 2014", "Due"],
    ["#669345", "4", "1,166,667", "22 Jan 2019", "Due"],
    ["#669748", "5", "1,166,667", "22 Feb 2022", "Due"],
    ["#349059", "6", "1,166,667", "23 July 2022", "Due"]
];

interface UserResponseData {
    activated: boolean;
    full_name: string;
    full_phone_number: string  | null;
    country_code: string | null;
    email: string  | null;
    phone_number: string | null;
    gender: string | null;
    type: string  | null;
    user_type: string  | null;
    user_name: string  | null;
    address: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    zipcode: number | null;
    business_name: string | null;
    tax_number: string | null;
    created_at: string  | null;
    updated_at: string  | null;
    device_id: string | null;
    unique_auth_id: string  | null;
    kyc_name: string | null;
    kyc_number: string | null;
    kyc_verified: boolean | null;
    kyc_document:string | null,
    profile_image: string | null;
    images: {
        tax_registration: Document,
        license_certification:Document,
        business_registration:Document,
        other_documents:Document
    };
};

interface UserProfileResponse {
    attributes: UserResponseData
};

interface Document {
    file_name: string | null
    url: string | null;
    size: string | null;
};

export interface ValidResponseType {
    data: AdminOffer[] | UserOfferData[] | BusinessDealItem[] | UserProfileResponse | OfferDetailShow | BusinessDealItem;
    message: string;
    meta: {
        total_count: number,
        total_pages: number,
        current_page: number,
        next_page: number | null,
        prev_page: number | null
    };
};

export interface ErrorPayloadType {
    key: string;
    token: string;
};
  
export interface InvalidResponseType {
    errors: Array<ErrorPayloadType>;
};

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    myOffers: UserOfferData[];
    adminOffers: AdminOffer[];
    currentLanguage:'ar'|'en';
    breadscrumValues: string[];
    headingText: string;
    selectedOffer: string;
    isOfferAvailable: boolean;
    businessList: BusinessDealItem[];
    businessDealDeatail: BusinessDealItem;
    businewwDealList: typeof businewwDealList;
    anchorEl: HTMLElement | null;
    userName: string; 
    userEmail: string; 
    userCountry: string;
    userState: string; 
    userCity: string; 
    phone: string; 
    zipcode: string; 
    suppDoc: (Document | File | null)[];
    otherDocs: (Document | File | null)[];
    showDetail: boolean;
    showModal: boolean;
    search: string;
    status: string;
    showFilter: boolean;
    startDate: string;
    endDate: string;
    userType: string;
    page: number;
    totalPages: number;
    totalResult: number;
    showFullList: boolean;
    offerDetailShow : OfferDetailShow;
    selectedMenuItemDetail: {status: string | null, userId: string | null};
    userId: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AllListingController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    myOfferBlankCheck: string = "";
    offerListApiCallId: string = "";
    fetchProfileApiCallId: string = "";
    offerOnListDetailApiCallId: string = "";
    acceptRejectofferOnListApiCallId = "";
    deleteMyOfferCallId: string = "";
    getBusinessDealCallId: string = "";
    getBusinessDealDetailCallId: string= "";
    acceptRejectofferOnDealApiCallId: string = "";
    cancelDealApiCallId: string = "";
    getAdminOffersApiCallId = "";
    updateAdminStatusApiCallId = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            myOffers: [],
            adminOffers: [],
            currentLanguage:'en',
            breadscrumValues:[],
            headingText: "",
            selectedOffer: "myOffer",
            isOfferAvailable: true,
            businessList: [],
            businessDealDeatail: businessDealDetail,
            businewwDealList: businewwDealList,
            anchorEl: null,
            userName: "", 
            userEmail: "", 
            userCountry: "",
            userState: "", 
            userCity: "", 
            phone: "", 
            zipcode: "", 
            suppDoc: Array(2).fill(null),
            otherDocs: Array(2).fill(null),
            showDetail: false,
            showModal: false,
            search: "",
            showFilter: false,
            status: "",
            startDate: "",
            endDate: "",
            userType: "",
            page: 1,
            totalPages: 8,
            totalResult: 48,
            showFullList: true,
            offerDetailShow: offerDetailShowDummyValue,
            selectedMenuItemDetail: {status: null, userId: null},
            userId: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiSuccessCallBack(apiRequestCallId, responseJson);
            } else {
                this.apiFailureCallBack(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const dummyFile = new File([''], 'example.txt', { type: 'text/plain' });
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Offers;
        let getToken = await getStorageData("accessToken");
        let getUser = await getStorageData("userType");
        let userId = await getStorageData("userId")
        if ( !getToken ) {
            this.handleNavigatingRoute("Home");
        } else {
            if( !getUser ) {
                getUser = "admin_account";
                this.getAdminOffers(1);
            } else {
                getUser = "consumer";
                this.getMyofferDataCheck();
                this.getMyOfferListData(1);
            }
        }
        this.setState({ 
            currentLanguage: language,
            headingText: heading,
            suppDoc: [dummyFile, dummyFile],
            otherDocs: [dummyFile, dummyFile],
            userType: getUser,
            userId: userId
        });
        this.getUserProfile();
        // Customizable Area End
    }

    // Customizable Area Start

    apiSuccessCallBack = (apiCallId: string, responseJson: ValidResponseType) => {
        if (apiCallId === this.myOfferBlankCheck) {
            this.myOfferBlankCheckSuccessCallBack(responseJson.data as UserOfferData[]);
        }
        if (apiCallId === this.offerListApiCallId) {
            this.getOfferListSuccessResp(responseJson);
        }
        if (apiCallId === this.fetchProfileApiCallId) {
            const responseData = responseJson.data as UserProfileResponse;
            this.getProfileApiSuccess(responseData.attributes);
        }
        if (apiCallId === this.offerOnListDetailApiCallId) {
            const responseData = responseJson.data as OfferDetailShow
            this.getOfferDetailSuccessResponse(responseData);
        }
        if (apiCallId === this.acceptRejectofferOnListApiCallId || apiCallId === this.updateAdminStatusApiCallId) {
            const responseData = responseJson.data as OfferDetailShow
            this.handleAcceptRejectOfferListing(responseData);
        }
        if (apiCallId === this.deleteMyOfferCallId) {
            this.getMyofferDataCheck();
            this.handlebreadScrumClick("breadScrum0");
        }
        if (apiCallId === this.getBusinessDealCallId) {
            this.setState({
                businessList: responseJson.data as BusinessDealItem[],
                page: responseJson.meta.current_page,
                totalPages: responseJson.meta.total_pages,
                totalResult: responseJson.meta.total_count
            });
        }
        if (apiCallId === this.getBusinessDealDetailCallId) {
            this.setState({
                businessDealDeatail: responseJson.data as BusinessDealItem
            }, () => this.handleOfferDetailOpen());  
        }
        if (apiCallId === this.acceptRejectofferOnDealApiCallId) {
            this.getBusinessDealDetails(this.state.businessDealDeatail.id);
        }
        if (apiCallId === this.cancelDealApiCallId) {
            this.setState({anchorEl: null});
            this.getBusinessDeal(this.state.page);
        }
        if (apiCallId === this.getAdminOffersApiCallId) {
            this.getOfferListSuccessResp(responseJson);
        }
    };

    apiFailureCallBack = (apiCallId: string, _responseJson: InvalidResponseType) => {
        if (apiCallId === this.fetchProfileApiCallId) {
            this.getProfileApiFailure();
        }
    };

    getAdminOffers = async(page: number) => {
        const { status, search, startDate, endDate } = this.state;
        const endPoint = configJSON.adminOffersEndPoints;
        const params = `?type=marketplace_offers&status=${status}&per_page=10&page=${page}&search=${search}&from=${startDate}&to=${endDate}`;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAdminOffersApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${endPoint}${params}`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updatAdminOfferStatusApi = async (status: string, offerId: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateAdminStatusApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.makeOfferEndPoint}/${offerId}/update_status_admin`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );
        const formData = new FormData()
        formData.append("admin_status", status);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getMyofferDataCheck = async () => {
        const params = `?per_page=2&page=1`;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.myOfferBlankCheck = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.myOffersEndPoint}${params}`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getMyOfferListData = async (page: number) => {
        const { status, search, startDate, endDate} = this.state;
        const endPoint = this.state.selectedOffer === "myOffer"? configJSON.myOffersEndPoint : configJSON.OffersOnMyListingEndPoint;
        const params = `?status=${status}&per_page=10&page=${page}&search=${search}&from=${startDate}&to=${endDate}`;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offerListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${endPoint}${params}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getListingOfferDetail = async (offerId: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offerOnListDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.makeOfferEndPoint}/${offerId}/listing_offer`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getBusinessDeal = async (page: number) => {
        const { status, search, startDate, endDate } = this.state;
        const params = `?status=${status}&per_page=10&page=${page}&search=${search}&from=${startDate}&to=${endDate}`;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBusinessDealCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.BusinessDealEndPoint}${params}`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getBusinessDealDetails = async (dealId: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBusinessDealDetailCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.BusinessDealEndPoint}${dealId}`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    acceptRejectBusinessDeal = async (status: string, dealId: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.acceptRejectofferOnDealApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.BusinessDealEndPoint}/${dealId}/update_status`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        const formData = new FormData()
        formData.append("[status]", status);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    cancelBusinessDealApi = async (dealId: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cancelDealApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.BusinessDealEndPoint}/${dealId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodDelete
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    deleteMyOfferApi = async (offerId: string) => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteMyOfferCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.makeOfferEndPoint}/${offerId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodDelete
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    acceptRejectListingOffer = async (status: string, offerId: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.acceptRejectofferOnListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.makeOfferEndPoint}/${offerId}/update_status`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );
        const formData = new FormData()
        formData.append("[status]", status);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUserProfile = async () => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchProfileEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getProfileApiSuccess = (resp: UserResponseData) => {
        this.setState({
            userName : `${resp.full_name}`,
            userEmail: `${resp.email}`,
            phone: `${resp.full_phone_number}`,
            userCountry: `${resp.country}`,
            userState: `${resp.state}`,
            userCity: `${resp.city}`,
            zipcode: `${resp.zipcode}`
        });
    };

    getOfferListSuccessResp = (responseJson: ValidResponseType) => {
        if (responseJson.data) {
            this.setState({ 
                myOffers: responseJson.data as UserOfferData[],
                page: responseJson.meta.current_page,
                totalPages: responseJson.meta.total_pages,
                totalResult: responseJson.meta.total_count
            });
        } else {
            if(this.state.page === 1) {
                this.setState({ 
                    myOffers: []
                });
            } else {
                this.setState({
                }, ()=> this.getMyOfferListData(1));
            }
        }
    };

    myOfferBlankCheckSuccessCallBack = (responseData: UserOfferData[]) => {
        if (responseData) {
            this.setState({
                isOfferAvailable: true
            });
        } else {
            this.setState({
                isOfferAvailable: false
            });
        }
    };

    getOfferDetailSuccessResponse = (responseData: OfferDetailShow) => {
        this.setState({
            offerDetailShow: responseData,
            suppDoc: responseData.attributes.resource.data.attributes.supporting_documents[0],
            otherDocs: responseData.attributes.resource.data.attributes.supporting_documents[0]
        }, ()=> {
            if(!this.state.showDetail) {
                this.handleOfferDetailOpen();
            }
        });
    };

    handleAcceptRejectOfferListing = (responseData: OfferDetailShow) => {
        if(this.state.showDetail) {
            this.getListingOfferDetail(responseData.id);
            this.setState({
                showModal: false,
                showFullList: false
            });
        } else {
            this.setState({
                anchorEl: null,
                selectedMenuItemDetail: {status: null, userId: null},
            });
            if(this.state.userType === "consumer") {
                this.getMyOfferListData(this.state.page);
            } else {
                this.getAdminOffers(this.state.page);
            }
        }
    };

    getProfileApiFailure = () => {
        if(this.state.userType === "consumer") {
            this.logoutHandle();
        }
    };

    handleNavigatingRoute = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleDrawerNavigation = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle();
        } else {
            if(routing.includes("breadScrum")) {
                this.handlebreadScrumClick(routing);
            } else {
                this.handleNavigatingRoute(routing);
            }
        }
    };

    handlebreadScrumClick = (clickedOn: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        if(clickedOn==="breadScrum0"){
            this.setState({
                showDetail: false, 
                breadscrumValues:[],
                headingText: languageData.Offers,
                showFullList: true,
                anchorEl: null
            }, () => {
                if(this.state.userType === "consumer"){
                    if(this.state.selectedOffer === "businessDeals") {
                        this.getBusinessDeal(this.state.page);
                    } else {
                        this.getMyOfferListData(this.state.page);
                    }
                } else {
                    this.getAdminOffers(this.state.page);
                }
            });
        }
    };

    logoutHandle = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        await removeStorageData("userId");
        await removeStorageData("otpToken");
        await removeStorageData("signupStatus");
        await removeStorageData("accessSignupToken");
        this.handleNavigatingRoute("Home");
    };

    handleOfferSelect = (select: string) => {
        if(this.state.userType === "admin_account" && select === "businessDeals") {
            return
        } else {
            this.setState({
                selectedOffer: select, 
                showFilter: false,
                search: "",
                startDate: "",
                endDate: "",
                status: "",
                page: 1,
                showFullList: true
            }, () => {
                if(this.state.userType === "consumer"){
                    if(this.state.selectedOffer === "businessDeals") {
                        this.getBusinessDeal(1);
                    } else {
                        this.getMyOfferListData(1);
                    }
                } else {
                    this.getAdminOffers(1);
                }
            });
        }
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, status: string | null, userId: string | null) => {
        this.setState({anchorEl:event.currentTarget, showFilter: false, selectedMenuItemDetail: {status: status, userId: userId}});
    };

    handleMenuClose = () => {
        this.setState({anchorEl:null, selectedMenuItemDetail: {status: null, userId: null}});
    };

    handleModalOpen = () => {
        this.setState({showModal: true});
    };

    handleModalClose = () => {
        this.setState({showModal: false});
    };

    handleStatusColorChange = (getValue: string) => {
        const value = getValue.toLowerCase();
        let changeBackground = "#FEF3C7";
        let changeColor = "#D97706";
        if (value === "accepted" || value === "paid") {
            changeBackground = "#D1FAE5";
            changeColor = "#059669";
        }
        if (value === "rejected") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor
        };
    };

    handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        if(this.state.userType === "consumer") {
            if(this.state.selectedOffer === "businessDeals") {
                this.getBusinessDeal(page);
            } else {
                this.getMyOfferListData(page);
            }
        } else {
            this.getAdminOffers(page);
        }
    };

    handleOfferDetailOpen = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        let array: string[], heading;
        if( this.state.selectedOffer!=="businessDeals" || this.state.userType === "admin_account") {
            array=[languageData.MyOffers, languageData.OfferDetails];
            heading= languageData.OfferDetails;
        } else {
            array=[languageData.MyOffers, languageData.DealDetails];
            heading= languageData.DealDetails;
        }
        this.setState({
            showDetail: true,
            breadscrumValues: array,
            anchorEl: null,
            headingText: heading,
            showFullList: false
        });
    };

    handleFilterOpen = () => {
        this.setState({showFilter: !this.state.showFilter});
    };

    handleCloseFilter = () => {
        this.setState({showFilter: false});
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            search : event.target.value
        }, () =>  {
            if (this.state.userType === "consumer") {
                if(this.state.selectedOffer === "businessDeals") {
                    this.getBusinessDeal(this.state.page);
                } else {
                    this.getMyOfferListData(this.state.page);
                }
            } else {
                this.getAdminOffers(1);
            }
        });
    };

    handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>);
    };

    handleSelect = (event: React.ChangeEvent<{ value:unknown}>) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({
                status: value
            });
        } 
    };

    resetDate = () => {
        this.setState({startDate: "", endDate: ""});
    };

    resetSelect = () => {
        this.setState({status: ""});
    };

    resetAll = () => {
        this.setState({
            startDate: "", 
            endDate: "",
            status: ""
        }, () =>  {
            if (this.state.userType === "consumer") {
                if(this.state.selectedOffer === "businessDeals") {
                    this.getBusinessDeal(this.state.page);
                } else {
                    this.getMyOfferListData(this.state.page);
                }
            } else {
                this.getAdminOffers(1);
            }
        });
    };

    handleFilterApplyNow = () => {
        if (this.state.userType === "consumer") {
            if(this.state.selectedOffer === "businessDeals") {
                this.getBusinessDeal(this.state.page);
            } else {
                this.getMyOfferListData(this.state.page);
            }
        } else {
            this.getAdminOffers(1);
        }
    };

    toArabicNumeral = (num: string): string => {
        const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        return String(num).replace(/\d/g, (d) => arabicNumerals[parseInt(d)]);
    };

    formatDate(isoString: string) {
        const date = new Date(isoString);
        const day = date.getUTCDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        return `${day} ${month} ${year}`;
    };

    handleFullListShow = () => {
        this.setState({showFullList: !this.state.showFullList});
    };

    handleDetailCancelReject = () => {
        if(this.state.userType === "consumer") {
            if(this.state.selectedOffer === "offerListing") {
                this.acceptRejectListingOffer("rejected", this.state.offerDetailShow.id);
            } else {
                this.deleteMyOfferApi(this.state.offerDetailShow.id);
            }
        }
        else {
            this.updatAdminOfferStatusApi("rejected", this.state.offerDetailShow.id);
        }
    };

    getUserName = (userInfo: BusinessDealItem["attributes"]["debt_owner_name"]["data"]["attributes"]) => {
        let name;
        switch (userInfo.sub_type) {
            case "bank":
                name = userInfo.bank_name
                break;
            case "agency":
                name = userInfo.agency_name
                break;
            case "service_provider":
                name = userInfo.service_provider_name
                break;
            default:
                name = userInfo.full_name
                break;
        }
        return name;
    };

    // Customizable Area End
}
