import { Box, Typography, Table, Paper, Button, Card, CardContent, TableHead, TableRow, TableCell, TableBody, Chip, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, styled } from "@material-ui/core";
import { removeStorageData } from "../../framework/src/Utilities";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
const languageTranslationData = require('../../blocks/languagesupport/src/LanguageData/PlanTransactions.json');
const utills = require('../../blocks/utilities/src/utills');
import UserProfileDrawer from "./design-system/UserProfileDrawer/UserProfileDrawer.web";
import Text from "./design-system/Text";
import { profileImg, visaIcon, componyNameLogo, qrCode } from './assets/index';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
// @ts-ignore
import ReactToPrint from "react-to-print";
import moment from "moment";

const renderInvoicePrint = (currentLanguage="en") => {
    const languageData = languageTranslationData[currentLanguage];
    const cellStyle: React.CSSProperties = { textAlign: currentLanguage === "en"? "left": "right"};
    return (
        <Box style={{ display: "none" }}>
          <Paper dir={currentLanguage === "ar"? "rtl": "ltr"} style={{ padding: "39px 45px" }}>
            <img
              src={componyNameLogo}
              style={{ width: "172px", marginBottom: "45px" }}
              alt="compony_name_logo"
            />
            <Box style={webStyle.colleInv}>
              <Typography
                style={{ fontSize: "20px", color: "#0F172A", fontWeight: 600 }}
              >
                {languageData.SubscriptionInvoice}
              </Typography>
              <Typography
                style={{ fontSize: "20px", color: "#0F172A", fontWeight: 500 }}
              >
                INV-1001
              </Typography>
            </Box>
            <PrintDivider />
            <Box style={webStyle.colleInv}>
              <Typography>
                <span style={{ color: "#64748B", fontSize: "14px" }}>
                  {languageData.Listingname}:
                </span>
                <strong> {languageData.MedicalDebtPackage}</strong>
              </Typography>
              <Typography>
                <span style={{ color: "#64748B", fontSize: "14px" }}>
                  {languageData.InvoiceDate}:
                </span>
                <strong> {languageData.startDate}</strong>
              </Typography>
              <Typography>
                <span style={{ color: "#64748B", fontSize: "14px" }}>
                  {languageData.DueDate}:
                </span>
                <strong> {languageData.Date}</strong>
              </Typography>
            </Box>
            <PrintDivider style={{ marginBottom: "35px" }} />
            <Box style={{ ...webStyle.colleInv, marginBottom: "45px" }}>
              <Box style={webStyle.fromToBox}>
                <Typography style={{ color: "#1D4ED8" }}>{languageData.From}:</Typography>
                <Typography
                  style={{ fontSize: "18px", color: "#0F172A", fontWeight: 700 }}
                >
                  {languageData.JohnDoe}
                </Typography>
                <Typography>{languageData.DummyAddress}</Typography>
                <Typography>{languageData.CityStateZIP}</Typography>
                <Typography>{languageData.DummyNumber}</Typography>
                <Typography>info@debtcollections.com</Typography>
              </Box>
              <Box style={webStyle.fromToBox}>
                <Typography style={{ color: "#1D4ED8" }}>{languageData.To}:</Typography>
                <Typography
                  style={{ fontSize: "18px", color: "#0F172A", fontWeight: 700 }}
                >
                  {languageData.CompanyName}
                </Typography>
                <Typography>{languageData.DummyAddress}</Typography>
                <Typography>{languageData.CityStateZIP}</Typography>
                <Typography>{languageData.DummyNumber}</Typography>
                <Typography>jane.smith@example.com</Typography>
              </Box>
            </Box>
            <Table style={{ marginTop: 20 }}>
              <TableHead style={{ borderRadius: "12px", overflow: "clip"}}>
                <PrintTableHeadStyle>
                  <TableCell style={cellStyle}>
                    {languageData.InvoiceTableHeading.Description}
                  </TableCell>
                  <TableCell style={cellStyle}>{languageData.InvoiceTableHeading.Quantity}</TableCell>
                  <TableCell style={cellStyle}>{languageData.InvoiceTableHeading.UnitPrice}</TableCell>
                  <TableCell style={cellStyle}>
                    {languageData.InvoiceTableHeading.Total}
                  </TableCell>
                </PrintTableHeadStyle>
              </TableHead>
              <TableBody>
                <PrintTableBodyStyle>
                  <TableCell style={cellStyle}>{languageData.YearlySubscription}</TableCell>
                  <TableCell style={cellStyle}>{languageData.InvoiceTableRow.One}</TableCell>
                  <TableCell style={cellStyle}>{languageData.InvoiceTableRow.SAR500}</TableCell>
                  <TableCell style={cellStyle}>{languageData.InvoiceTableRow.SAR500}</TableCell>
                </PrintTableBodyStyle>
              </TableBody>
            </Table>
            <Divider style={{ marginBottom: "25px" }} />
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Box style={{ width: "fit-content" }}>
                <Typography
                  style={{ ...webStyle.subTotal, marginBottom: "10px" }}
                >
                  {languageData.SubtotalAmountDue}:
                  <span style={webStyle.subTotalValue}>&ensp;{languageData.InvoiceTableRow.SAR500}</span>
                </Typography>
                <Typography style={{ ...webStyle.subTotal, marginBottom: "10px" }}>
                  {languageData.Taxes15}:
                  <span style={webStyle.subTotalValue}> {languageData.SAR75}</span>
                </Typography>
                <Typography style={webStyle.subTotal}>
                  {languageData.TotalAmountDue}:
                  <span style={webStyle.subTotalValue}> {languageData.SAR575}</span>
                </Typography>
              </Box>
            </Box>
            <PrintDivider style={{ margin: "35px 0 30px" }} />
            <Box
              style={{
                ...webStyle.colleInv,
                gap: "50px",
                color: "#64748B",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              <Box>
                <Typography style={webStyle.paymentInstHeading}>
                  {languageData.PaymentInstructions}:
                </Typography>
                <Typography style={{ color: "#0F172A" }}>
                  {languageData.PaymentDescription}
                </Typography>
              </Box>
              <Box style={webStyle.printBottomRigBox}>
                <Typography>
                  {languageData.DueDate}:
                  <span style={webStyle.pamInstValue}> {languageData.Date}</span>
                </Typography>
                <Typography style={{ whiteSpace: "nowrap" }}>
                  {languageData.PaymentMethods}:
                  <span style={webStyle.pamInstValue}>
                    {" "}
                    {languageData.PaymentMode}
                  </span>
                </Typography>
                <Typography>
                  {languageData.BankName}:<span style={webStyle.pamInstValue}> {languageData.ABCBank}</span>
                </Typography>
                <Typography>
                  {languageData.AccountNumber}:
                  <span style={webStyle.pamInstValue}> {languageData.ContactNumber}</span>
                </Typography>
              </Box>
            </Box>
            <img
              src={qrCode}
              style={{ width: "84px", marginTop: "40px" }}
              alt="qr_code"
            />
          </Paper>
        </Box>
    );
};

const OfferDetails = (props: any) => {
    console.log("Manoj OfferDetails", props)
    const [breadscrumarray, setBreadscrumarray] = useState([])
    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [headingText, setHeadingText] = useState("")

    const languageData = languageTranslationData[currentLanguage];
    const subscription = languageData.SubscriptionDescription;

    const { location } = props;

    const handleRouting = (selectRoute: string) => {
        props.navigation.navigate(selectRoute)
    };

    const handleDrawer = (route:string)=>{
        if(route==="logOut"){
            handleLogOut();
        } else {
            if(route.includes("breadScrum")) {
                handleBreadScrumClick(route);
            } else {
                handleRouting(route);
            }
        }
    };

    const handleLogOut = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        handleRouting("Home");
    };

    const handleBreadScrumClick = (clickOn: string) => {
        const languageData = languageTranslationData[currentLanguage]; 
        if(clickOn==="breadScrum0"){
            setBreadscrumarray([])
            setHeadingText(languageData.MyListings)
        }
    };

    const onMount = async () => {
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const languageData = languageTranslationData[language];
        setCurrentLanguage(language)
        setHeadingText(languageData.Heading)
    }

    useEffect(() => {
        onMount()
    }, [])

    if(!location.state) {
        return (
            <UserProfileDrawer
                data-test-id="sideDrawerTestID"
                handleClick={handleDrawer}
                pageName="PlanTransactions"
                breadScrumArray={breadscrumarray}
                userType = {"admin_account"}
                navigation={props.navigation}
            >

                <Box>
                    <Box style={{ marginBottom: "16px" }}>
                        <HeadingText>
                            {headingText}
                        </HeadingText>
                        <Typography>No Data available</Typography>
                    </Box>
                </Box>
            </UserProfileDrawer>
        )
    }

    const { account } = location.state.attributes

    return (
        <UserProfileDrawer
                data-test-id="sideDrawerTestID"
                handleClick={handleDrawer}
                pageName="PlanTransactions"
                breadScrumArray={breadscrumarray}
                userType = {"admin_account"}
                navigation={props.navigation}
            >

                <Box>
                    <Box style={{ marginBottom: "16px" }}>
                        <HeadingText>
                            {headingText}
                        </HeadingText>
                    </Box>
                    <Box>
                    <Box sx={webStyle.main}>
                        <Box>
                            <Text variant="primary" size="mm" weight="lightBold">{`#${location.state.id}`}</Text>
                            <Box display="flex" mt={1} alignItems="center" mb={2} gridGap="10px">
                                <Box borderRadius="40px">
                                    <Chip style={webStyle.successchip} label={location.state.attributes.status} />
                                </Box>
                                <Text size="xx" weight="bold" variant="neutrals">{moment(location.state.attributes.created_at).format("DD MMM YYYY")}</Text>
                            </Box>
                        </Box>
                        <Divider />
                        <Box mt={3} mb={3}>
                            <Text size="mm" variant="lightBlue" weight="lightBold">{languageData.UserInformation}</Text>
                            <Box display="flex" gridGap="10px" alignItems="center" mt={3} mb={3}>
                                <img src={account.data.attributes.profile_image || profileImg} style={{ height: "80px", width: "80px", borderRadius: "50%" }} />
                                <Box display="flex" flexDirection="column">
                                    <Text size="mm" weight="lightBold" variant="lightBlue">{`${account.data.attributes.first_name || ""} ${account.data.attributes.last_name || ""}`}</Text>
                                    <Text size="sm" weight="minBold" variant="grey">{account.data.attributes.email}</Text>
                                </Box>
                            </Box>
                            <Grid container spacing={2} style={webStyle.mainDetail}>
                                <Grid item xs={2} sm={2} style={webStyle.detail}>
                                    <Text size="xm" weight="lightBold" variant="lightBlue">{account.data.attributes.phone_number}</Text>
                                    <Text size="sm" weight="minBold" variant="grey">{languageData.PhoneNumber}</Text>
                                </Grid>
                                <Grid item xs={2} sm={2} style={webStyle.detail}>
                                    <Text size="xm" weight="lightBold" variant="lightBlue">{account.data.attributes.country}</Text>
                                    <Text size="sm" weight="minBold" variant="grey">{languageData.Country}</Text>
                                </Grid>
                                <Grid item  xs={2} sm={2} style={webStyle.detail}>
                                    <Text size="xm" weight="lightBold" variant="lightBlue">{account.data.attributes.state}</Text>
                                    <Text size="sm" weight="minBold" variant="grey">{languageData.State}</Text>
                                </Grid>
                                <Grid item  xs={2} sm={2} style={webStyle.detail}>
                                    <Text size="xm" weight="lightBold" variant="lightBlue">{account.data.attributes.city}</Text>
                                    <Text size="sm" weight="minBold" variant="grey">{languageData.City}</Text>
                                </Grid>
                                <Grid item  xs={2} sm={2} style={webStyle.detail}>
                                    <Text size="xm" weight="lightBold" variant="lightBlue">{account.data.attributes.zipcode}</Text>
                                    <Text size="sm" weight="minBold" variant="grey">{languageData.Zip}</Text>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box mt={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} md={6} sm={6}>
                                    <Text size="mm" variant="lightBlue" weight="lightBold">{languageData.PlanPricing}</Text>
                                    <Box mt={2}>
                                        <Card style={webStyle.plans}>
                                            <CardContent style={webStyle.content}>
                                                <Chip style={webStyle.chip} label={languageData.Active} />
                                                <Box display="flex" alignItems="center">
                                                    <Text size="1xl" variant="lightBlue" weight="extraBold">{languageData.Price}  </Text>/<Text size="xm" variant="lightBlue" weight="bold">{languageData.PerYear}</Text>
                                                </Box>
                                                <Box mt={2}>
                                                    <Text size="1xl" variant="lightBlue" weight="lightBold" lineHeight="44px">{languageData.YearlySubscription}</Text>
                                                </Box>
                                                <List>
                                                    { subscription.map((service: string) => 
                                                        <ListItem style={webStyle.services} key={service}>
                                                            <ListItemIcon style={webStyle.servicesIcon}>
                                                                <CheckCircleRoundedIcon htmlColor="#34D399" />
                                                            </ListItemIcon>
                                                            <StyledLisItemText>
                                                                {service}
                                                            </StyledLisItemText>
                                                        </ListItem>
                                                    )}
                                                    </List> 
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={6} sm={6}>
                                    <Text size="mm" variant="lightBlue" weight="lightBold">{languageData.PaymentMethod}</Text>
                                    <Box mt={2}>
                                        <Card style={webStyle.payment}>
                                            <CardContent style={webStyle.paymentContent}>
                                                <Box>
                                                    <Text>{languageData["**** 8239"]}</Text>
                                                    <Box display="flex" marginTop="10px">
                                                        <Text>{languageData.Visa}</Text>
                                                        <Button style={{ padding: 0, textTransform: "none"}} variant="text"><Text>{languageData.Edit}</Text></Button>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <img src={visaIcon} />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box display="flex" gridGap="10px" mt={3}>
                        <ReactToPrint
                            trigger={() => 
                                <Button variant="contained" 
                                    style={webStyle.exportButtonStyle}
                                >
                                    {languageData.ExportTransactions}
                                </Button>
                            }
                            documentTitle = "Subscription_Invoice"
                        />
                        <Button variant="contained" 
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 700, padding: "10px 24px", color: "#0F172A", backgroundColor: "#EFF6FF", textTransform: "none" }}>
                            {languageData.Refund}</Button>
                    </Box>
                    {renderInvoicePrint(currentLanguage)}
                </Box>
                </Box>
        </UserProfileDrawer>
    )
}

export default withRouter(OfferDetails)

const webStyle = {
    main: {
        padding: 20,
        backgroundColor: "#fff",
        boxShadow: "0px 8px 32px 0px #0000000F",
    },
    printBottomRigBox: {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    } as React.CSSProperties,
    colleInv: {
        display: "flex",
        justifyContent: "space-between"
    },
    fromToBox: {
        fontSize: "14px",
        color: "#1E293B",
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    } as React.CSSProperties,
    subTotal: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#1E293B",
        display: "flex",
        justifyContent: "space-between"
    },
    subTotalValue: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#0F172A",
        textAlign: "right"
    } as React.CSSProperties,
    paymentInstHeading: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#0F172A",
        marginBottom: "15px"
    },
    pamInstValue: {
        fontWeight: 500,
        color: "#0F172A",
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    plans: {
        borderRadius: 28,
        border: '1px solid #BFDBFE',
        boxShadow: 'none'
    },
    exportButtonStyle: {
        borderRadius: 8, 
        fontSize: 16, 
        fontWeight: 700, 
        padding: "10px 24px", 
        color: '#FFFFFF', 
        backgroundColor: "#1E3A8A",
        textTransform: "none"
    } as React.CSSProperties,
    payment: {
        borderRadius: 8,
        border: '1px solid #BFDBFE',
        boxShadow: 'none'
    },
    content: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        background: '#EFF6FF'
    },
    paymentContent: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#EFF6FF"
    },
    description: {
        listStyleType: 'disc',
        paddingLeft: 15,
    },
    descriptionItem: {
        fontSize: 14 as const,
        fontWeight: 400
    },
    services: {
        paddingBottom: 2,
        paddingLeft: 0
    },
    servicesIcon: {
        minWidth: 32 as const
    },
    chip: {
        display: "flex",
        width: "65px",
        marginLeft: 'auto',
        color: '#1D4ED8',
        backgroundColor: "#DBEAFE"
    },
    successchip: {
        display: "flex",
        width: "90px",
        fontSize: 12,
        fontWeight: 700,
        color: '#059669',
        backgroundColor: "#D1FAE5",
        height: "20px",
        marginTop: "6px",
    },
    mainDetail: {
        display: "flex",
        justifiyContent: "space-between"
    },
    detail: {
        flexBasis: "20%",
        maxWidth: "20%"
    }
}

const StyledLisItemText = styled(ListItemText)({
    padding: 0,
    '& span': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "22px"
    }
});

const PrintTableHeadStyle = styled(TableRow)({
    backgroundColor: "#F8FAFC",
    "& .MuiTableCell-root": {
      borderBottom: "none",
      fontSize: "14px",
      fontWeight: 600,
      color: "#64748B"
    }
});
  
const PrintDivider = styled(Divider)({
    margin: "22px 0"
});
  
const PrintTableBodyStyle = styled(TableRow)({
    "& .MuiTableCell-root": {
      borderBottom: "none",
      fontSize: "14px",
      fontWeight: 500,
      color: "#0F172A"
    }
});

const HeadingText = styled(Typography)({
    fontSize: "30px",
    fontWeight: 700,
    color: "#0F172A"
})