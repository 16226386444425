import React from "react";
import { Box, styled, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";


const PrivacyPolicy = (props: any) => {
    const { location } = props;
    return (
        <MainBox>
            <HeadingText variant="h5">
                {location.pathname == "/privacy" ? "Privacy Policy" : "Terms & Conditions"}
            </HeadingText>
            {
                [0, 1, 2, 3].map((item: any, index: number) => {
                    return (
                        <BodyText>
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece 
                            of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, 
                            a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure 
                            Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word 
                            in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. 
                            Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced
                            in their exact original form, accompanied by English versions from the 1914 translation 
                            by H. Rackham.
                        </BodyText>
                    )
                })
            }
        </MainBox>
    )
}

export default withRouter(PrivacyPolicy)

const MainBox = styled(Box)({
    width: "100%",
    maxWidth: "1200px",
    margin: "0px auto",
    padding: "40px 16px",
    boxSizing: "border-box",
    "@media (max-width: 500px)": {
        padding: "30px 16px"
    }
})

const HeadingText = styled(Typography)({
    textAlign: "center"
})

const BodyText = styled(Typography)({
    marginTop: "50px"
})