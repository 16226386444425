import React from "react";
// Customizable Area Start
import RequestsWebController, { Props } from "./RequestsController.web";
import { withStyles, StyleRules, styled } from "@material-ui/core/styles";
import { Box, Modal, Typography, Button, IconButton, MenuItem, TextField,Avatar,Grid, TableContainer, Paper, FormControl, Menu, InputAdornment, Select, Popover, InputLabel } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import { acceptImg, closeImg, deleteRedImg, filterImg, groupImg, pendingImg, rejectImg, verifiedImg } from "./assets";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextInput from "../../../../packages/components/src/design-system/TextInput";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Request.json')
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class RequestsWeb extends RequestsWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentLanguage, anchorEl, dot, statusCounts} = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <UserProfileDrawer
                data-test-id="drawerComponentTestID"
                handleClick={this.handleDrawerClick}
                pageName="UserRequests"
            >
                <Box style={classes.RequestSuperBox}>
                    <Typography style={classes.typographyHeader}>{languageData.VerificationRequest}</Typography>
                    {!this.state.verificationRequestListAvailable ? (
                            <DefaultBoxWrapper>
                                <DefaultBox>
                                    <DefaultImage src={groupImg}></DefaultImage>
                                    <DefaultBoxText>{languageData.defaultBoxText}</DefaultBoxText>
                                    <RequestButton 
                                        data-test-id = "blankCreateVerificationButtonTestId"
                                        onClick={this.handleFirstModalOpen} 
                                        variant="contained"
                                    >
                                        {languageData.newRequest}
                                    </RequestButton>
                                </DefaultBox>
                            </DefaultBoxWrapper>
                        ) : (<>
                            <Box style={classes.vMainBox}>
                                <InnerBox>
                                    <img style={classes.vImg} src={acceptImg} />
                                    <InnerTextBox>
                                        <Typography style={classes.vApprovedText}>{languageData.Approved}</Typography>
                                        <Typography style={classes.vApprovedNumber}>{statusCounts.approved}</Typography>
                                    </InnerTextBox>
                                </InnerBox>

                                <InnerBox>
                                    <img style={classes.vImg} src={rejectImg} />
                                    <InnerTextBox>
                                        <Typography style={classes.vApprovedText}>{languageData.Rejected}</Typography>
                                        <Typography style={classes.vApprovedNumber}>{statusCounts.rejected}</Typography>
                                    </InnerTextBox>
                                </InnerBox>

                                <InnerBox>
                                    <img style={classes.vImg} src={pendingImg} />
                                    <InnerTextBox>
                                        <Typography style={classes.vApprovedText}>{languageData.Pending}</Typography>
                                        <Typography style={classes.vApprovedNumber}>{statusCounts.pending}</Typography>
                                    </InnerTextBox>
                                </InnerBox>
                            </Box>

                    <Box style={classes.tableWrapper}>

                        <TableUpperBox>

                            <CustomTextFieldForm
                                data-test-id = "filterSearchTestId"
                                variant="outlined"
                                placeholder={languageData.Search}
                                value={this.state.search}
                                onChange={(event) => this.handleSearch(event)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={classes.searchIcon} />
                                        </InputAdornment>
                                    ),

                                }}/>
                            <Box style={classes.buttonMainBox}>
                                <TableCell style={{ border: 'none', position: "relative" }}>
                                    <FilterButton
                                        data-test-id="openFilterButtonTestId"
                                        variant="outlined" 
                                        onClick={this.handleMenuOpen}
                                    >
                                        <FilterListRoundedIcon style={{ width: "20px",marginRight:"8px" }} />
                                        <FilterButtonText>{languageData.Filter}</FilterButtonText>
                                    </FilterButton>
                                    <PopoverBox
                                        data-test-id="filterMenuTestId"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleMenuClose}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left'
                                        }}
                                    >
                                        <PopoverSubBox>
                                            <PopoverHeaderBox>
                                                <PopoverFilterBox>
                                                    <img style={classes.filterBtn} src={filterImg} alt="filter" />
                                                    <FilterButtonText>{languageData.Filter}</FilterButtonText>
                                                </PopoverFilterBox>
                                                <img onClick={this.handleMenuClose} style={classes.filterCloseButton} src={closeImg} alt="close" />
                                            </PopoverHeaderBox>
                                            <PopoverInputWrapper>
                                                <ResetButtonText
                                                    data-test-id = "dateResetTestId"
                                                    onClick = {this.handleFilterDateReset}
                                                >
                                                    {languageData.Reset}
                                                </ResetButtonText>
                                                <Box style={classes.inputBox}>
                                                    <Box>
                                                        <LabelText>
                                                            {languageData.From}
                                                        </LabelText>
                                                        <CustomTextFieldDate
                                                            data-test-id="filterdatetestId"
                                                            value={this.state.fromDate}
                                                            variant="outlined"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder={languageData.DatePlaceholder}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>this.handleFilterDateChange(event, "fromDate")}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <LabelText>
                                                            {languageData.To}
                                                        </LabelText>
                                                        <CustomTextFieldDate
                                                            variant="outlined"
                                                            value={this.state.toDate}
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder={languageData.DatePlaceholder}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>this.handleFilterDateChange(event, "toDate")}
                                                        />
                                                    </Box>
                                                </Box>
                                            </PopoverInputWrapper>
                                            <StatusBox>
                                            <ResetButtonText
                                                data-test-id = "filterSelectResetTestId"
                                                onClick={()=>this.handleFilterSelectReset()}
                                            >{languageData.Reset}</ResetButtonText>
                                                <Box style={classes.inputBox}>
                                                    <LabelText>{languageData.Status}</LabelText>
                                                    <FormControl fullWidth>
                                                        <CustomInputLabel shrink={false} style={{ display: !this.state.status ? "block" : "none", lineHeight: "0" }}>
                                                            {languageData.Accepted}
                                                        </CustomInputLabel>
                                                        <FilterSelect
                                                            data-test-id = "filterSelectTestId"
                                                            fullWidth
                                                            IconComponent={KeyboardArrowDownRoundedIcon}
                                                            variant="outlined"
                                                            name="UserManagement"
                                                            value={this.state.status}
                                                            onChange={(event)=>this.handleSelect(event, "status")}
                                                        >
                                                            <MenuItem value={"accepted"}>{languageData.Accepted}</MenuItem>
                                                            <MenuItem value={"rejected"}>{languageData.Rejected}</MenuItem>
                                                            <MenuItem value={"pending"}>{languageData.Pending}</MenuItem>

                                                        </FilterSelect>
                                                    </FormControl>
                                                </Box>
                                            </StatusBox>
                                            <PopoverButtonWrapper>
                                                <ResetButton 
                                                    data-test-id="filterResetAllTestId"
                                                    variant="contained"
                                                    onClick={()=>this.handleFilterResetAll()}
                                                >
                                                    {languageData.Reset}
                                                </ResetButton>
                                                <ApplyButton 
                                                    data-test-id="filterApplyTestId"
                                                    onClick={() => {
                                                        this.handleFilterForVerification(this.state.currentPage);
                                                    }} 
                                                    variant="contained"
                                                >
                                                    {languageData.Apply}
                                                </ApplyButton>
                                            </PopoverButtonWrapper>
                                        </PopoverSubBox>
                                    </PopoverBox>
                                </TableCell>
                                <RequestButton variant="contained" onClick={this.handleFirstModalOpen}>
                                    <Typography>{languageData.RequestVerification}</Typography>
                                </RequestButton>
                            </Box>
                        </TableUpperBox>
                        {this.state.requesrUserdata.length === 0?
                            <Box style = {classes.noResultMatchBox}>
                                <Typography style = {classes.noResultMatchText}>
                                    {languageData.NoResultMatch}
                                </Typography>
                            </Box>:
                            <>
                            <TableContainer style={classes.tableContainer} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <CustomHeadingRow>
                                        <TableCell>{languageData.RequestID}</TableCell>
                                        <TableCell>{languageData.Name}</TableCell>
                                        <TableCell>{languageData.RegistrationNumber}</TableCell>
                                        <TableCell>{languageData.Date}</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.Status}</TableCell>
                                    </CustomHeadingRow>
                                </TableHead>
                                <TableBody>
                                {this.state.requesrUserdata?.length > 0 && this.state.requesrUserdata.map((item, index) => (
                                    <CustomBodyRow key = {index} >
                                            <TableCell>#{item.id}</TableCell>
                                            <TableCell>{item.attributes.full_name}</TableCell>
                                            <TableCell>{item.attributes.registration_number}</TableCell>
                                            <TableCell>{this.formatDate(item.attributes.request_date)}</TableCell>
                                            <TableCell>
                                                <span
                                                    style={{
                                                        ...classes.statusStyle,
                                                          ...this.handleStatusColor(item.attributes.status),
                                                          textTransform:"uppercase"
                                                    }}
                                                >
                                                    {item.attributes.status}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton 
                                                    data-test-id = {`reqMoreButton${index}`}
                                                    onClick={(event) => this.handleClick(event,item.id)}
                                                >
                                                    <MoreVertIcon style={classes.moreOpStyle}/>
                                                </IconButton>
                                                <Menu
                                                    anchorEl={dot}
                                                    keepMounted
                                                    open={Boolean(dot)}
                                                    onClose={this.handleClose}
                                                    getContentAnchorEl={null}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center'
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            overflow: 'visible',
                                                            boxShadow: '0px 0px 8px 4px #00000008',
                                                            width: "160px",
                                                            borderRadius: "8px",
                                                            border: "1px solid #E2E8F0"
                                                        }
                                                    }}
                                                >
                                                    <ViewRequest 
                                                        data-test-id="menuViewDetailTestId"
                                                        onClick={() => this.handleNavigation('UserRequest')}
                                                    >{languageData.ViewDetails}</ViewRequest>
                                                    <CancelRequest 
                                                        data-test-id="menuCancelRequiestTestId"
                                                        onClick={()=>this.deleteVerificationRequiest(`${this.state.selectedRequest}`)}
                                                    >
                                                        <img src={deleteRedImg} /> 
                                                        {languageData.CancelRequest}
                                                    </CancelRequest>
                                                </Menu>
                                            </TableCell>
                                        </CustomBodyRow>
                                    ))}
                                </TableBody>
                            </Table>
                                </TableContainer>
                                <Box style={classes.paginationBox}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <CustomPagination
                                            data-test-id="tablePaginationSecondTestID"
                                            count={this.state.totalPages}
                                            page={this.state.currentPage}
                                            onChange={this.handlePageChange}
                                            shape="rounded"
                                            variant="outlined"
                                            siblingCount={1}
                                            boundaryCount={1}
                                        />
                                    </Grid>
                                    <PaginationCount>
                                        {`${(this.state.currentPage - 1) * this.state.itemsPerPage + 1} - ${Math.min(this.state.currentPage * this.state.itemsPerPage, this.state.totalCount)} of ${this.state.totalCount} results`}
                                    </PaginationCount>
                                </Box>
                            </>}
                            </Box>
                    </>
                     )
                    }
                    <StyledModal
                        data-test-id = "planModalTestId"
                        open={this.state.isFirstModalOpen}
                        onClose={this.handleFirstModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}
                    >
                        <ModalContent>
                            <ModalContentText>
                                <ModalHeadingText>
                                    {languageData.UpgradeRequests}
                                </ModalHeadingText>
                                <CloseButton onClick={this.handleFirstModalClose}>
                                    <img style={classes.filterCloseButton} src={closeImg} alt="close image" />
                                </CloseButton>
                            </ModalContentText>
                            <ModalDecText>
                                    {languageData.UpgradeRequestsDec}
                            </ModalDecText>
                            <ModalButtonBox>
                                <BasicPlan
                                    data-test-id = "modalBasicButtonTestId"
                                    onClick={this.handleSecondModalOpen}
                                    variant="contained"
                                >
                                    {languageData.ContinueBasic}
                                </BasicPlan>
                                <PlanSection 
                                    data-test-id = "modalPlanButtonTestId"
                                    onClick={() => this.handleNavigation('Customisableusersubscriptions')} 
                                    variant="contained"
                                >
                                    {languageData.PlanSection}
                                </PlanSection>
                            </ModalButtonBox>
                        </ModalContent>
                    </StyledModal>
                    <StyledModal2
                        data-test-id = "createReqModal"
                        open={this.state.isSecondModalOpen}
                        onClose={this.handleSecondModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}
                    >
                        <ModalContent2>
                            <form data-test-id = "formTestId" onSubmit={this.handlePostapi}>
                            <Box style={classes.modalButtonBox}>       
                                <SecondModalHeading>
                                    {languageData.newRequest}
                                </SecondModalHeading>
                                <CloseIconBox>
                                    <CloseIcon onClick={this.handleSecondModalClose} />
                                </CloseIconBox>
                            </Box>
                            <Box sx={classes.inputMainContainer}>
                                <Typography style={classes.inputMainContainerHeading}>
                                    {languageData.CompletelyAnonymous}
                                </Typography>
                                <InputFieldWrapper>
                                    <InputfieldLabel>{languageData.NameEntity}</InputfieldLabel>
                                    <CustomTextField
                                        data-test-id="entityNametestId"
                                        variant="outlined"
                                        placeholder={languageData.NameEntity}
                                        value={this.state.entityName}
                                        required
                                        onChange={(event) => this.handleInputChange('entityName', event.target.value)}/>
                                </InputFieldWrapper>
                                <InputFieldWrapper>
                                    <InputfieldLabel>{languageData.RegistrationNumber}</InputfieldLabel>
                                    <CustomTextField
                                        data-test-id="regNumTestId"
                                        variant="outlined"
                                        value={this.state.registrationNumber}
                                        placeholder={languageData.RegistrationNumber}
                                        required
                                        onChange={(event) => this.handleInputChange('registrationNumber', event.target.value)}/>
                                </InputFieldWrapper>
                               <Typography style={classes.errorMessage}>{this.state.registrationNumberError}</Typography> 
                                <InputFieldWrapper>
                                    <InputfieldLabel>{languageData.TypeOfEntity}</InputfieldLabel>
                                    <FormControl fullWidth>
                                        <CustomInputLabel shrink={false} style={{ display: !this.state.entityType ? "block" : "none", lineHeight: "0" }}>
                                            {languageData.TypeOfEntity}
                                        </CustomInputLabel>
                                        <FilterSelectFields
                                            fullWidth
                                            value = {this.state.entityType}
                                            data-test-id="listTypeTestID"
                                            IconComponent={KeyboardArrowDownRoundedIcon}
                                            variant="outlined"
                                            name="UserManagement"
                                            onChange={(event) => this.handleInputChange('entityType', event.target.value as string)}>
                                            <MenuItem value={"option 1"}>Type 1</MenuItem>
                                            <MenuItem value={"option 2"}>Type 2</MenuItem>

                                        </FilterSelectFields>
                                    </FormControl>
                                </InputFieldWrapper>
                                <InputFieldWrapperLast>
                                    <InputfieldLabel>{languageData.AdditionalDetails}</InputfieldLabel>
                                    <CustomTextField
                                        data-test-id="detailTestId"
                                        variant="outlined"
                                        placeholder={languageData.AdditionalDetails}
                                        value={this.state.additionalDetails}
                                        required
                                        onChange={(event) => this.handleInputChange('additionalDetails', event.target.value)}/>
                                </InputFieldWrapperLast>
                            </Box>
                            <SecondModalButtonBox>
                                <BasicPlan onClick={this.handleSecondModalClose} variant="contained" >
                                    {languageData.Cancel}
                                </BasicPlan>
                                <PlanSection
                                    data-test-id="createVerificationSubmit"
                                    type="submit"  
                                    variant="contained"
                                >
                                    {languageData.RequestVerificationButton}
                                </PlanSection>
                            </SecondModalButtonBox>
                            </form>
                        </ModalContent2>
                    </StyledModal2>
                    <StyledModal3
                        open={this.state.isThirdModalOpen}
                        onClose={this.handleThirdModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}>
                        <ModalContent3>
                            <ModalThirdBox>
                                <img style={classes.thirdModalImage} src={verifiedImg}/>
                                <ModalThirdHeading>
                                    {languageData.SubmittedSuccessfully}
                                </ModalThirdHeading>
                                <ModalThirdDec>
                                    {languageData.ThirdModalText}
                                </ModalThirdDec>
                                <GoToRequestButton onClick={this.handleThirdModalClose} variant="contained">
                                    {languageData.GoToRequests}
                                </GoToRequestButton>
                            </ModalThirdBox>
                        </ModalContent3>
                    </StyledModal3>
                    <Modal open={this.state.agreeModal} id='agreemodal' onClose={this.handleModalConfirm}>
                        <ModalWrapper>
                            <Text align="center" lineHeight="30px" weight="extraBold" size="xl" variant="darkBlack">
                                {languageData.AcceptVerification}
                            </Text>
                            <Box style={{ margin: "16px 0 31px 0px" }}>
                                <Text align="center" lineHeight="20px" weight="minBold" size="sm" variant="darkBlack">
                                    {languageData.EnterTheOTP}
                                </Text>
                                <Box style={{ width: '99.9%', }}>
                                    <TextInput
                                        data-test-id={"NumbertxtInput"}
                                        variant="outlined"
                                        color="#747A80"
                                        label="OTP"
                                        type="text"
                                        value={""}
                                    />
                                </Box>
                            </Box>
                            <ModalButtonContainer>
                                <ButtonCancelWrapper id="modalCancel" onClick={this.handleModalConfirm}>
                                    {languageData.Cancel}
                                </ButtonCancelWrapper>
                                <ButtonWrapper id="modalConfirm" onClick={() => this.handleModalConfirm()}>
                                    {languageData.Confirm}
                                </ButtonWrapper>
                            </ModalButtonContainer>
                        </ModalWrapper>
                    </Modal>
                    <Modal open={this.state.cancelModal} id="cancelModal" onClose={this.handleModalBlockConfirm}>
                        <ModalWrapper>
                            <Text align="center" lineHeight="30px" weight="extraBold" size="xl" variant="darkBlack" >
                                {languageData.RejectVerification}
                            </Text>
                            <Box mr={0} ml={0} mt={3.75} mb={3.75}>
                                <Text align="center" lineHeight="20px" weight="minBold" size="xx" variant="darkBlack">
                                    {languageData.ConfirmIf}
                                </Text>
                            </Box>
                            <ModalButtonContainer>
                                <ButtonCancelWrapper id="modalBlockCancel" onClick={this.handleModalBlockConfirm}>
                                    {languageData.Cancel}
                                </ButtonCancelWrapper>
                                <ButtonWrapper id="modalBlockConfirm" onClick={() => this.handleModalBlockConfirm()}>
                                    {languageData.Confirm}
                                </ButtonWrapper>
                            </ModalButtonContainer>
                        </ModalWrapper>
                    </Modal>   
                </Box>
            </UserProfileDrawer>


        )
        // Customizable Area End
    }
}

export default withStyles(styles)(RequestsWeb);
const CustomPagination = withStyles({
    ul: {
      '& .MuiPaginationItem-root': {
        color: '#0F172A',
        border: 'none',
      },
      '& .Mui-selected': {
        color: '#1D4ED8',
        fontWeight: 'bold',
        background: 'none',
      },
      '& .MuiPaginationItem-ellipsis': {
        color: '#1F2937',
      },
      '& .MuiPaginationItem-icon': {
        color: '#0F172A',
      },
      '& .MuiPaginationItem-page:not(.Mui-selected)': {
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
      }
    }
  })(Pagination);
const ModalWrapper = styled(Box)({
    boxSizing: "border-box",
    padding: "36px 128px",
    backgroundColor: "white",
    border: "1px solid #DFE0EB",
    borderRadius: "8px",
    width: "90%",
    maxWidth: "600px",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    top: "50%",
    left: "50%",
    "@media (max-width:767px)": {
        padding: "20px 30px",
    }
});

const CustomInputLabel = styled(InputLabel)({
    fontSize: "14px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
      display: "none",
      color: "#94A3B8"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const CustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});
const CustomResultBoxText = styled(Typography)({
    color: "#64748B", 
    fontSize: "12px",
});
const DefaultBoxWrapper = styled(Box)({
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"70vh"
 })
const DefaultBox = styled(Box)({
   width:"287px",
   height:"328px",
   position:"relative",
   display:"flex",
   flexDirection:"column",
   gap:"20px",
   justifyContent:"center",
   alignItems:"center",
})
const DefaultImage = styled(Avatar)({
    width:"150px",
    height:"166px",
 })
 const DefaultBoxText = styled(Typography)({
    color:"#000000",
    fontSize:"18px",
    fontWeight:500,
    lineHeight:"26px",
    textAlign:"center",
 })
const FilterSelect = styled(Select)({
    width: "100%",
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    height: "44px",
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent",
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});
const FilterSelectFields = styled(Select)({
    fontSize: "14px",
    width: '480px',
    height: '44px',
    color: "#0F172A",
    fontWeight: 400,
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent",
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});
const ModalButtonContainer = styled(Box)({
    display: "flex",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
})
const ButtonCancelWrapper = styled(Box)({
    padding: "12px 60px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
    fontSize: '14px',
    fontWeight: 600,
    color: "#3751FF",
    cursor: "pointer",
    border: "1px solid #3751FF",
    transition: "all 0.3s ease",
    width: '50%',
    "&:hover": {
        color: "#3751FF",
        backgroundColor: "white"
    }
})

const ButtonWrapper = styled(Box)({
    padding: "11px 16px",
    backgroundColor: "#3751FF",
    borderRadius: "8px",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
    fontSize: '14px',
    fontWeight: 600,
    color: "white",
    cursor: "pointer",
    transition: "all 0.3s ease",
    border: "1px solid #3751FF",
    width: '50%',
    textAlign: 'center',
    "&:hover": {
        backgroundColor: "#3751FF"
    }
})

const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))
const StyledModal2 = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledModal3 = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const ModalContent = styled(Box)({
    borderRadius: '20px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    background: "#FFFFFF",
    height: 'auto',
    maxWidth: '560px',
    padding: '30px',
    "@media (max-width: 500px)": {
        padding: '15px'
    }
})
const ModalContent2 = styled(Box)({
    position: 'absolute',
    left: "440px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: '20px',
    background: "#FFFFFF",
    height: '622px',
    width: '560px',
    display: "flex",
    flexDirection: "column",
    gap: "24px"
})

const ModalContent3 = styled(Box)({
    position: 'absolute',
    left: "440px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: '20px',
    background: "#FFFFFF",
    height: '521px',
    width: '686px',
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingTop: "40px"
})
const CloseButton = styled(IconButton)(({ theme }) => ({
    
}));
const CustomTextField = withStyles({
    root: {
        width: '480px',
        height: '44px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        color: "#0F172A",
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

const TableUpperBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
    gap: "20px",
    flexWrap: "wrap",
});
const RequestButton = styled(Button)({
    backgroundColor: "#1E3A8A",
    width: "auto",
    height: "44px",
    borderRadius: "8px",
    cursor: "pointer",
    color:"#FFFFFF",
    textTransform:"capitalize",
    "& .MuiTypography-root": {
        whiteSpace: "nowrap",
        width: "auto",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 700,
        color: "white"
    },
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});
const FilterButton = styled(Button)({
    padding: "10px 16px",
    borderRadius: "8px",
    display: "flex",
    gap: "8px",
    alignItems: "center"

});

const CustomTextFieldDate = withStyles({
    root: {
        padding: '10px 8px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
        '& .MuiInputBase-input': {
            padding: '10px 8px',
            border: '1px solid #CBD5E1',
            borderRadius: '8px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
    },
})(TextField);

const CustomTextFieldForm = withStyles({
    root: {
        maxWidth: '320px',
        width: "100%",
        height: '44px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

const CustomHeadingRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});
const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});

const InnerBox = styled(Box)({
    border: '1px solid #E2E8F0',
    height: '114px',
    padding: '20px',
    width: '143px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '@media (max-width: 768px)': {
        height: '100px',      
        width: '120px',       
        gap: '18px',          
        padding: '16px',      
    },
    '@media (max-width: 480px)': { 
        height: 'auto',       
        width: '100%',        
        gap: '12px',          
        padding: '12px',     
    },

});
const InnerTextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

});
const FilterButtonText = styled(Box)({
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "capitalize",

});
const PopoverBox = styled(Popover)({
    position: "absolute",
    top: "60px",
    left: "-60px",
    borderRadius: "12px"

});

const PopoverSubBox = styled(Box)({
    maxWidth: "374px", 
    width: "100%",
    display: "flex", 
    flexDirection: "column", 
    gap: "24px", 
    padding: "0px 0px 20px 0px", 
    borderRadius: "12px", 
    border: "1px solid #CBD5E1"

});
const PopoverHeaderBox = styled(Box)({
    padding: "20px", 
    borderBottom: "1px solid #E2E8F0", 
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center"

});
const PopoverFilterBox = styled(Box)({
    display: "flex", 
    gap: "8px", 
    alignItems: "center"

});
const PopoverInputWrapper = styled(Box)({
    padding: "0 20px", 
    display: "flex", 
    flexDirection: "column", 
    gap: "8px"

});
const ResetButtonText = styled(Typography)({
    fontSize: "14px", 
    fontWeight: 700, 
    color: "#1E3A8A", 
    textAlign: "end"

});
const LabelText = styled(Typography)({
    fontFamily: 'Inter', 
    fontSize: '14px', 
    fontWeight: 700, 
    lineHeight: '22px', 
    textAlign: 'left'

});
const StatusBox = styled(Box)({
    padding: "0 20px", 
    display: "flex", 
    flexDirection: "column", 
    gap: "8px"

});
const PopoverButtonWrapper = styled(Box)({
    borderTop: "1px solid #E2E8F0", 
    padding: "20px", 
    display: "flex", 
    justifyContent: "space-between"

});
const ResetButton = styled(Button)({
    padding:"10px 16px", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    borderRadius: '8px', 
    backgroundColor: '#EFF6FF', 
    fontSize: '16px', 
    fontWeight:700, 
    color: '#0F172A', 
    textTransform: 'none'

});
const ApplyButton = styled(Button)({
    padding: "10px 16px", 
    borderRadius: '8px', 
    backgroundColor: '#1E3A8A', 
    fontSize: '14px', 
    fontWeight: 700, 
    color: '#FFFFFF', 
    textTransform: 'none'

});
const ViewRequest = styled(MenuItem)({
    color: "#0F172A", 
    fontSize: "14px", 
    fontWeight: 400

});
const CancelRequest = styled(MenuItem)({
    color: "#DC2626", 
    fontSize: "14px", 
    fontWeight: 400, 
    display: "flex", 
    alignItems: "center", 
    gap: "8px"

});
const ModalContentText = styled(Box)({
    borderBottom: "1px solid #E2E8F0",
    maxWidth: '560px',
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center"
});
const ModalHeadingText = styled(Typography)({
    fontSize: '20px', 
    fontWeight: 700, 
    lineHeight: "28px", 
    color: '#0F172A'
});
const ModalDecText = styled(Typography)({
    fontSize: '14px', 
    fontWeight: 400, 
    color: '#475569', 
    lineHeight: "22px", 
    marginTop: '20px'
});
const ModalButtonBox = styled(Box)({
    display: 'flex', 
    gap: '16px', 
    width: "100%",
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px'
});
const PaginationCount = styled(Typography)({
    whiteSpace: "nowrap",
    color: "#64748B",
    fontSize: "12px",
    fontWeight: 400,

});
const BasicPlan = styled(Button)({
    width: "248px", 
    height: "56px", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    borderRadius: '8px', 
    backgroundColor: '#EFF6FF', 
    fontSize: '16px', 
    fontWeight: 700, 
    color: '#0F172A', 
    textTransform: 'none',
    whiteSpace:"nowrap",

});
const PlanSection = styled(Button)({
    alignItems: "center", 
    width: "248px", 
    height: "56px", 
    backgroundColor: '#1E3A8A', 
    display: "flex", 
    justifyContent: "center", 
    borderRadius: '8px', 
    whiteSpace:"nowrap",
    fontSize: '16px', 
    fontWeight: 700, 
    color: '#FFFFFF', 
    textTransform: 'none'

});
const SecondModalHeading = styled(Typography)({
    position: "relative",  
    left: "40px", 
    fontSize: '24px', 
    fontWeight: 700, 
    color: '#0F172A', 
    letterSpacing: "0.5%", 
    lineHeight: "32px" 

});
const InputFieldWrapper = styled(Box)({
    display: 'flex', 
    flexDirection: 'column', 
    gap: '4px'

});
const InputFieldWrapperLast = styled(Box)({
    marginBottom:"20px",
    display: 'flex', 
    flexDirection: 'column', 
    gap: '4px',

});
const InputfieldLabel = styled(Typography)({
    fontSize: '14px', 
    fontWeight: 700, 
    color: '#334155', 
    lineHeight: "22px"
});
const SecondModalButtonBox = styled(Box)({
    display: 'flex', 
    gap: '16px', 
    width: '560px', 
    height: "104px", 
    padding: '24px'
});
const CloseIconBox = styled(Box)({
    position:"relative",
    right:"40px"
});
const ModalThirdBox = styled(Box)({
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    gap: '24px' 
});
const ModalThirdHeading = styled(Typography)({
    fontSize: '30px', 
    fontWeight: 700, 
    color: '#0F172A', 
    textAlign: 'center', 
    width: "606px", 
    lineHeight: "44px", 
    letterSpacing: "-0.5%" ,
});
const ModalThirdDec = styled(Typography)({
    fontSize: '18px', 
    fontWeight: 400, 
    color: '#475569', 
    textAlign: 'center', 
    width: "496px", 
    lineHeight: "26px",
});
const GoToRequestButton = styled(Button)({
    justifyContent: "center", 
    width: "248px", 
    height: "56px", 
    display: "flex", 
    textTransform: 'none',
    alignItems: "center", 
    borderRadius: '8px', 
    backgroundColor: '#1E3A8A', 
    fontSize: '16px', 
    fontWeight: 700, 
    color: '#FFFFFF', 
});

const classes: any = {
    RequestSuperBox: {
        padding: "0px 0px 0px 0px"
    },
    typographyHeader: {
        fontWeight: 700, 
        fontSize: "30px", 
        lineHeight: "44px", 
        letterSpacing: "0.5%", 
        color: "#0F172A",
        '@media (max-width: 600px)': { 
            fontSize: "24px", 
            lineHeight: "36px", 
        },
        '@media (max-width: 400px)': { 
            fontSize: "20px", 
            lineHeight: "30px", 
        }

    },
    noResultMatchBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "325px"
    },
    noResultMatchText: {
        fontSize: "30px",
        color: "#94A3B8"
    },
    verificationBox: {
        height: '80vh', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'

    },
    verificationInnerBox: {
        display: 'flex', 
        gap: '20px', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '328px', 
        width: '287px'

    },
    groupImg: {
        height: "166px", 
        width: '150px'

    },
    verificationText: {
        fontSize: '18px', 
        fontWeight: 500, 
        textAlign: 'center', 
        color: '#000000', 
        lineHeight: "26px"

    },
    requestButton: {
        padding: "10px 16px", 
        borderRadius: '8px', 
        backgroundColor: '#1E3A8A', 
        fontSize: '14px', 
        fontWeight: 700, 
        color: '#FFFFFF', 
        textTransform: 'none'
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: "18px",
        whiteSpace: "nowrap"
    },
    searchButtonWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexGrow: 1,
        width: "320px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    searchIconStyle: {
        color: "#94A3B8"
    },
    textFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        height: "44px",
        width: "320px"
    },
    moreOpStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    statusStyle: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEF3C7",
        // textTransform:"uppercase", 
    },
    filterBtn: {
        height: "10px",
        width: "15px"
    },
    buttonMainBox: {
        display: "flex",
        gap: "16px",
        alignItems: "center",
        flexWrap: "wrap"
    },

    drawerPaper: {
        padding: '16px',
        width: '320px',
    },
    filterBoxHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
    formControl: {
        marginBottom: '16px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
    },
    inputBox: {
        display: 'flex',
        gap: '16px',
        flexWrap: 'wrap',
        width: "100%"
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        textAlign: 'left',
    },
    inputField: {
        width: '159px',
        height: '44px',
        gap: '8px',
        border: "1px solid #CBD5E1",
        borderRadius: "8px"
    },
    inputRoot: {
        width: '320px',
        height: '44px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',

        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    searchIcon: {
        width: '17.05px',
        height: '17.06px',
        marginTop: '3.47px',
        marginLeft: '3.48px',
        color: "#94A3B8"

    },
    placeholder: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#94A3B8',
    },
    vMainBox: {
        display:"flex",
        flexWrap: "wrap",
        gap: '20px',
        margin: '48px 0px',
        '@media (max-width: 768px)': { 
            width: '100%', 
            height: 'auto', 
            gap: '15px'
        },
        '@media (max-width: 480px)': { 
            width: '100%',  
            height: 'auto',  
            gap: '10px',   
            marginTop: '24px'

        },
    },

    vImg: {
        height: '24px',
        width: '24px',
    },

    vApprovedText: {
        color: '#94A3B8',
        lineHeight: '26px',
        fontSize: '18px',
        fontWeight: 500,
    },
    errorMessage: {
        color: "red",
        fontSize: '12px',
        fontWeight: 400,

    },
    vApprovedNumber: {
        color: '#0F172A',
        lineHeight: '32px',
        fontSize: '24px',
        fontWeight: 600,
        letterSpacing: '-0.5%',
    },
    mainResultBox: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        paddingTop: "16px"
    } as React.CSSProperties,

    paginationBox: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        marginTop:"24px"
      },
    tableWrapper: {
        background: "#FFFFFF",
        borderRadius: "12px", 
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px", 
        padding: "16px", 
        margin: "20px 0 80px 0",
    },
    filterCloseButton: {
        height: "24px", 
        width: "24px"
    },
    tableContainer: {
        boxShadow: "none", 
        width: "100%", 
        overflowX: "auto"
    },
    modalButtonBox: {
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        height: "80px", 
        borderBottom: "1px solid #E2E8F0",
        marginTop:"10px"
    },
    inputMainContainer: {
        borderBottom: "1px solid #E2E8F0", 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '16px', 
        padding: '0px 40px 0px 40px', 
        height: "390px",
    },
    inputMainContainerHeading: {
        fontSize: '14px', 
        fontWeight: 400, 
        color: '#64748B', 
        lineHeight: "22px"

    },
    thirdModalImage: {
        height: '120px', 
        width: '120px'
    }
   
 
   

}