import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Modal,
  Switch,
  SwitchProps,
  IconButton,
  TextField,
  Snackbar
} from "@material-ui/core";
import Text from "../../../../packages/components/src/design-system/Text";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CustomisableusersubscriptionsController, { Props, Subscription } from "./CustomisableusersubscriptionsController";
import Paragraph from "../../../../packages/components/src/design-system/Paragraph";
import AddIcon from '@material-ui/icons/Add';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { bgImageRight } from "../../../../packages/blocks/landingpage/src/assets";
const languageTranslation = require('../../languagesupport/src/LanguageData/Subscription.json')
// Customizable Area End

// Customizable Area Start

const MainBox = styled(Box)({
  background: `url(${bgImageRight}) no-repeat top right`,
  backgroundSize: "15% 50%"
});

const Section = styled(Container)({
  padding: '90px 90px',
  "@media (max-width: 1160px)": {
    padding: "40px 40px"
  },
  "@media (max-width: 1070px)": {
    padding: "40px 20px"
  },
  "@media (max-width: 600px)": {
    padding: "40px 20px",
  },
  '& .MuiInputBase-input-root': {
    padding: 12
  },
  '& .MuiInputBase-input:focus' : {
    outline: 'solid'
  }
})

const StyledLisItemText = styled(ListItemText)({
  padding: 0,
  '& span': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "22px"
  }
});

const SubscriptionGrid = styled(Grid)({
  "@media (max-width: 1040px)": {
    flexDirection: "column",
    gap: "20px"
  },
  "@media (max-width: 430px)": {
    "& .MuiCardContent-root": {
      padding: "16px 20px 20px !important"
    }
  }
});

const CustomChip = styled(Chip)({
  width: "fit-content !important",
  "& .MuiChip-label": {
    textOverflow: "clip"
  }
});

const CustomAccordion = styled(Accordion)({
  borderRadius: 12,
  marginBottom: 24,
  padding: "24px",
  "@media (max-width: 500px)": {
    padding: "8px"
  }
});

const ModBodyInnBox = styled(Box)({
  "@media (max-width: 610px)": {
    padding: "20px !important"
  }
});

const TextFieldStyle = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "8px",
      border: "1px solid #CBD5E1"
    }
  },
  "& .MuiInputBase-input": {
    height: "30px",
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: "400",
    "&.Mui-disabled": {
      WebkitTextFillColor: "#0F172A"
    }
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1
  }
});

const ModalBodyBox = styled(Box)({
  "& .MuiButton-root": {
    "@media (max-width: 610px)": {
      width: "100% !important"
    }
  }
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  padding: 0,
  height: 28,
  width: 48,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      left: 3,
      color: '#fff',
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1D4ED8',
        border: 0,
        opacity: 1
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff',
      color: '#33cf4d'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7
    }
  },
  '& .MuiSwitch-thumb': {
    width: 26,
    boxSizing: 'border-box',
    height: 26
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: '#E9E9EA',
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const ResetButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#0F172A",
  height: "56px",
  width: "248px",
  backgroundColor: "#EFF6FF",
  borderRadius: "8px",
  textTransform: "none",
  padding: "10px 16px",
  "&:hover": {
    backgroundColor: "#EFF6FF"
  }
});

const CreateButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 700,
  color: "white",
  height: "56px",
  width: "248px",
  backgroundColor: "#1E3A8A",
  borderRadius: "8px",
  textTransform: "none",
  padding: "10px 16px",
  "&:hover": {
    backgroundColor: "#1E3A8A"
  }
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

// Customizable Area End

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start


  renderInput = (
    label: string, 
    value: string, 
    stateName: string, 
    placeholder: string, 
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    error: string
  ) => {
    return (
      <Box
        style={
          {
            width: "100%"
          }
        }
      >
        <Typography style={webStyle.inputLabel}>{label}</Typography>
        <TextFieldStyle
          data-test-id={`${stateName}TestID`}
          value = {value}
          size="small"
          placeholder={placeholder}
          fullWidth
          name = {stateName}
          variant="outlined"
          onChange = {handleChange}
        />
        {(this.state.checkPaySubmit && error) &&
          <Typography 
            style={webStyle.errorText}
          >
            {error}
          </Typography>
        }
      </Box>
    );
  };

  renderModal = () => {
    const { currentLanguage, cardNumber, cardHolderName, expDate, cvvNumber, error, planSelected } = this.state;
    const LanguageData = languageTranslation[currentLanguage];
    const { Cardnumber, Cardholdername, ExpiryDate, datePlace, cvPlace, Paynow, Cancel, TotalAmount, perfectPlanFor, Automaticrenewal } = LanguageData;
    const dir = currentLanguage === "ar"? "rtl": "ltr";
    return (
      <Modal 
        data-test-id="modalTestID"
        open={this.state.showModal}
        onClose={this.handleCloseModal}
      >
        <Box style={webStyle.modalMainBox}>
          <ModalBodyBox dir={dir} style={webStyle.modalBodyBox}>
            <ModBodyInnBox style={webStyle.modHeadingBox}>
              <Typography style={webStyle.modHeading}>
                {LanguageData.Paymentdetails}
              </Typography>
              <IconButton onClick={this.handleCloseModal}>
                <CloseRoundedIcon style={{color: "#0F172A"}}/>
              </IconButton>
            </ModBodyInnBox>
            <Box style={{borderBottom: "1px solid #E2E8F0", margin: "0 35px", padding: "20px 0"}}>
              <Box style={{display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                <Box>
                  <Typography style={webStyle.selectedPlanNameText}>{planSelected.name}</Typography>
                  <Typography style={webStyle.selectedPlanForText}>{perfectPlanFor}</Typography>
                </Box>
                <Box>
                  <Typography style={webStyle.planSelectedPriceText}>
                    {this.engArabicNumeral(planSelected.price)}
                    <span style={webStyle.selectedPlanDurationText}>/{planSelected.cycle}</span>
                  </Typography>
                </Box>
              </Box>
              <Box style={{display: "flex", justifyContent: "space-between"}}>
                <Typography style={{color: "#0F172A"}}>{Automaticrenewal}</Typography>
                <IOSSwitch data-test-id="autoRenewTestId" checked={this.state.autoRenewal} onClick={this.handleAutoRenew}/> 
              </Box>
            </Box>
            <ModBodyInnBox style={webStyle.modMiddleBox}>
              {this.renderInput(Cardnumber, cardNumber, "cardNumber", "0000 0000 0000 0000", this.handleCardNumberChange, error.cardNumErr)}
              {this.renderInput(Cardholdername, cardHolderName, "cardHolderName", Cardholdername, this.handleCardHolderChange, error.cardHoldErr)}
              <Box style={webStyle.lastInputBox}>
                {this.renderInput(ExpiryDate, expDate, "expDate", datePlace, this.handleInputDateChange, error.expDateErr)}
                {this.renderInput("CVV", cvvNumber, "cvvNumber", cvPlace, this.handleCvvChange, error.cvvErr)}
              </Box>
            </ModBodyInnBox>
            <Box style={{margin: "20px 35px 0"}}>
              <Box style={{display: "flex", justifyContent: "space-between", marginBottom: "15px"}}>
                <Typography style={{color: "#64748B"}}>{planSelected.name} x{this.engArabicNumeral("1")}</Typography>
                <Typography style={{fontSize: "18px", fontWeight: 700, color: "#0F172A"}}>{this.engArabicNumeral(planSelected.price)}</Typography>
              </Box>
              <Box style={{display: "flex", justifyContent: "space-between"}}>
                <Typography style={{fontSize: "18px", color: "#64748B"}}>{TotalAmount}</Typography>
                <Typography style={webStyle.planSelectedPriceText}>{this.engArabicNumeral(planSelected.price)}</Typography>
              </Box>
            </Box>
            <ModBodyInnBox dir="ltr" style={webStyle.modButtonBox}>
              <ResetButton
                onClick={this.handleCloseModal}
              >
                {Cancel}
              </ResetButton>
              <CreateButton 
                data-test-id="addPaySubmitButtonTestID"
                onClick={this.handleMakePayment}
              >
                {Paynow}
              </CreateButton>
            </ModBodyInnBox>
          </ModalBodyBox>
        </Box>
      </Modal>
    );
  };

  // Customizable Area End

  render () {
    // Customizable Area Start
    const { currentLanguage, subscriptions } = this.state;
    const subscriptionTranslation = languageTranslation[currentLanguage];

    return (
      <MainBox>
        <Section>
          <Grid style={webStyle.heading} container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={12}>
              <Text variant="coolBlue" size="xm" weight="bold" lineHeight="26px" align="center">{subscriptionTranslation.Heading}</Text>
              <Text size="1xl" weight="lightBold" lineHeight="44px" align="center">{subscriptionTranslation.Title}</Text>
              <Paragraph color="#64748B" fontWeight={400} lineHeight="26px" fontSize="18px" align="center">{subscriptionTranslation.Description}</Paragraph>
            </Grid>
          </Grid>
          <SubscriptionGrid container xs={12} sm={12} spacing={1} alignItems="center" justifyContent="center">
            { subscriptions.map((subscription: Subscription, subIdx: number) => {
              return (
                <Grid item xs={12} sm={6} style={{ maxWidth: 515 }}>
                  <Card style={{ ...webStyle.plans, ...(subIdx === 0 && webStyle.activePlan) 
                  }}>
                    <CardContent style={webStyle.content}>
                        {subIdx === 0 && <CustomChip style={webStyle.chip} label={subscriptionTranslation.MostPopular} />}
                        <Box display="flex" alignItems="center">
                          <Text size="1xl" variant="lightBlue" weight="extraBold">{subscription.price}  </Text>/<Text size="xm" variant="lightBlue" weight="bold">{subscription.cycle}</Text>
                        </Box>
                        <Box mt={2}>
                          <Text size="1xl" variant="lightBlue" weight="lightBold" lineHeight="44px">{subscription.name}</Text>
                        </Box>
                        <Box component="ul" style={webStyle.description}>
                          <Box marginBottom="10px" marginLeft="-15px">
                            <Text size="xx" lineHeight="22px" weight="minBold">{subscriptionTranslation.IdealFor}</Text>
                          </Box>
                          { subscription.description.map((description: string) => 
                              <Typography style={webStyle.descriptionItem} component="li">{description}</Typography>
                            )
                          }
                        </Box>
                        <List>
                          { subscription.services.map((service: string) => 
                              <ListItem style={webStyle.services}>
                                <ListItemIcon style={webStyle.servicesIcon}>
                                  <CheckCircleRoundedIcon htmlColor="#34D399" />
                                </ListItemIcon>
                                <StyledLisItemText>
                                  {service}
                                </StyledLisItemText>
                              </ListItem>
                            )}
                          </List> 
                          <Button
                            data-test-id={`planSelectButton${subIdx}TestId`}
                            style={
                              subIdx === 0 ? webStyle.choosePlanBtn1 : webStyle.choosePlanBtn2 
                            } 
                            variant="contained" 
                            fullWidth
                            onClick={()=> this.handleSelectPlan(subIdx)}
                          >
                            {subscriptionTranslation.ChoosePlan}
                          </Button>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </SubscriptionGrid>
          <Grid style={webStyle.faqHeading} container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={12}>
              <Text variant="coolBlue" size="xm" weight="bold" lineHeight="26px" align="center">{subscriptionTranslation.Support}</Text>
              <Text size="1xl" weight="lightBold" lineHeight="44px" align="center">{subscriptionTranslation.Faq}</Text>
              <Paragraph color="#64748B" fontWeight={400} lineHeight="26px" fontSize="18px" align="center">{subscriptionTranslation.FaqDesc}</Paragraph>
            </Grid>
          </Grid>

          <Grid container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={12}>
              {subscriptionTranslation.Faqs.map((faq: {title: string, description: string}) => 
                <CustomAccordion>
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Text size="mm" weight="lightBold" lineHeight="26px">{faq.title}</Text>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paragraph color="#161616" fontWeight={400} lineHeight="24px" fontSize="16px">{faq.description}</Paragraph>
                  </AccordionDetails>
                </CustomAccordion>)}
            </Grid>
          </Grid>
        </Section>
        {this.renderModal()}
        <Snackbar 
          open={this.state.showSnackbar === "success"} 
          autoHideDuration={3000} 
          onClose={this.handleSnackbarClose}
          anchorOrigin={
            { 
              vertical: "top", 
              horizontal: "center" 
            }
          }
        >
          <Alert onClose={this.handleSnackbarClose} severity="success">
            {this.state.subscriptionMessage}
          </Alert>        
        </Snackbar>
        <Snackbar 
          data-test-id="errorSnackbarTestId"
          open={this.state.showSnackbar === "error"} 
          autoHideDuration={3000} 
          onClose={this.handleSnackbarClose}
          anchorOrigin={
            { 
              vertical: "top", 
              horizontal: "center" 
            }
          }
        >
          <Alert onClose={this.handleSnackbarClose} severity="error">
            {this.state.subscriptionMessage}
          </Alert>          
        </Snackbar>
      </MainBox>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  selectedPlanNameText: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#0F172A"
  },
  selectedPlanDurationText: {
    fontSize: "14px", 
    fontWeight: 700,
    color: "#64748B"
  },
  selectedPlanForText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#64748B"
  },
  chip: {
    display: "flex",
    width: "35%",
    marginLeft: 'auto',
    color: '#1D4ED8',
    backgroundColor: "#DBEAFE"
  },
  planSelectedPriceText: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#0F172A"
  },
  errorText: {
    color: "#DC2626",
    fontSize: "12px",
    marginTop: "6px"
  },
  inputLabel: {
    fontSize: "14px",
    color: "#334155",
    fontWeight: 700,
    marginBottom: "7px"
  },
  heading: {
    marginBottom: 32
  },
  modButtonBox: {
    display: "flex",
    gap: "20px",
    padding: "20px 35px 35px",
    flexWrap: "wrap"
  } as React.CSSProperties,
  modMiddleBox: {
    display: "flex",
    padding: "25px 35px 0",
    gap: "20px",
    flexDirection: "column"
  } as React.CSSProperties,
  modHeadingBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    padding: "25px 17px 17px 35px",
    borderBottom: "1px solid #E2E8F0"
  },
  lastInputBox: {
    display: "flex",
    gap: "20px",
    paddingBottom: "25px",
    borderBottom: "1px solid #E2E8F0"
  },
  modalMainBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  modHeading: {
    fontSize: "24px",
    color: "#0F172A",
    fontWeight: 700
  },
  modalBodyBox: {
    background: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 8px 4px #00000008",
    marginLeft: "10px",
    marginRight: "10px"
  },
  plans: {
    borderRadius: 28,
    border: '1px solid #BFDBFE',
    maxWidth: 476
  },
  content: {
    paddingLeft: 42,
    paddingRight: 42,
    paddingBottom: 42
  },
  activePlan: {
    backgroundColor: "#EFF6FF"
  },
  choosePlanBtn1: {
    textTransform: "none" as const,
    borderRadius: 8,
    marginTop: 10,
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: '#1E3A8A',
    color: '#FFFFFF',
    padding: 10,
    boxShadow: 'none'
  },
  choosePlanBtn2: {
    textTransform: "none" as const,
    borderRadius: 8,
    marginTop: 10,
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: '#EFF6FF',
    color: '#0F172A',
    padding: 10,
    boxShadow: 'none'
  },
  description: {
    listStyleType: 'disc',
    paddingLeft: 15,
    },
  descriptionItem: {
    fontSize: 14 as const,
    fontWeight: 400
  },
  services: {
    paddingBottom: 2,
    paddingLeft: 0
  },
  servicesIcon: {
    minWidth: 32 as const
  },
  faqHeading: {
    marginTop: 48,
    marginBottom: 32
  }
}
// Customizable Area End