import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { debounce } from "../../../components/src/utilities";
export const configJSON = require("./config");
const languageTranslationData = require('../../languagesupport/src/LanguageData/PlanTransactions.json');
const utills = require('../../utilities/src/utills');

type Pagination = {
    total_count: number,
    total_pages: number,
    current_page: number,
    next_page: number | null,
    prev_page: number | null
}

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentLanguage: string;
    userType: string;
    breadscrumarray: Array<string>;
    showPublished: boolean;
    publishAnchorEl: HTMLElement | null;
    listingType: string;
    status: string;
    showDetailPreview: boolean;
    showFilter: boolean;
    pageHeadingText: string;
    isLoading: boolean;
    transactions: any[],
    transactionDashboard: {
        total: number,
        total_revenue: string,
        pending: number,
        failed: number
    },
    rowsPerPage: number,
    pagination: Pagination,
    searchText: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class PlanTransactionsController extends BlockComponent<
Props,
S,
SS
> {

    constructor (props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentLanguage:"en",
            userType: 'admin',
            breadscrumarray: [],
            showPublished: false,
            publishAnchorEl: null,
            listingType: 'block',
            status: '',
            showDetailPreview: false,
            showFilter: false,
            pageHeadingText: "",
            isLoading: false,
            transactions: [],
            transactionDashboard: {
                total: 0,
                total_revenue: "0",
                pending: 0,
                failed: 0
            },
            rowsPerPage: 10,
            pagination: {
                total_count: 0,
                current_page: 1,
                total_pages: 0,
                next_page: null,
                prev_page: null
            },
            searchText: ""
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(responseJson.hasOwnProperty("error") || responseJson.hasOwnProperty("errors")){
                console.log("errors", responseJson)
                this.setState({ isLoading: false })
                return;
            }

            if (apiRequestCallId == this.getTransactionDashboardAPICallID) {
                this.setState({ transactionDashboard: responseJson })
            }

            if (apiRequestCallId == this.getTransactionsListAPICallID) {
                this.setState({ transactions: responseJson.data, pagination: responseJson.meta, isLoading: false })
            }
            
        }

        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const languageData = languageTranslationData[language];
        this.setState({ 
            currentLanguage: language,
            pageHeadingText: languageData.Heading
        });
        this.getTransactionDashboardData();
        this.getTransactionsListData()
        console.log("Manoj Plan", this.props)
        // Customizable Area End
    }

    // Customizable Area Start
    protected invoiceRef = React.createRef<HTMLDivElement>();
    getTransactionDashboardAPICallID : any;
    getTransactionsListAPICallID: any;

    getTransactionDashboardData = async () => {
        let token = await getStorageData("accessToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTransactionDashboardAPICallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.transactionDashboardEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    debouncedSearch = debounce(() => this.getTransactionsListData(), this)

    getTransactionsListData = async (page=1) => {
        this.setState({ isLoading: true })
        let token = await getStorageData("accessToken")
        
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTransactionsListAPICallID = requestMessage.messageId;

        const payload = {
            page: page.toString(),
            per_page: this.state.rowsPerPage.toString(),
            search: this.state.searchText.trim().toLowerCase()
        }

        const queryParams = new URLSearchParams(payload).toString()

        const Endpoint = `${configJSON.transactionsListEndpoint}?${queryParams}`

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            Endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: e.target.value }, () => {
          this.debouncedSearch()
        })
    }

    handleRouting = (selectRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleDrawer = (route:string)=>{
        if(route==="logOut"){
            this.handleLogOut();
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrumClick(route);
            } else {
                this.handleRouting(route);
            }
        }
    };

    handleLogOut = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleRouting("Home");
    };

    handleBreadScrumClick = (clickOn: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage]; 
        if(clickOn==="breadScrum0"){
            this.setState({
                showDetailPreview: false, 
                breadscrumarray:[],
                publishAnchorEl: null,
                pageHeadingText: languageData.MyListings
            });
        }
    };

    handleMenuClose = () => {
        this.setState({ publishAnchorEl: null });
    }

    handleDetailPreviewShow = () => {
        const languageData = languageTranslationData[this.state.currentLanguage]; 
        this.setState({
            showDetailPreview: true,
            breadscrumarray: [languageData.Heading, languageData.OfferTransactionDetails],
            publishAnchorEl: null,
            pageHeadingText: languageData.OfferDetails
        });
    }


    handlePublishMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({publishAnchorEl:event.currentTarget});
    };

    handleExportTransactions () {
        console.log("export transactions");
    }

    handleToggleFilter () {
        console.log("toggle filters");
    }

    handlePageChange = (event: any, page: number) => {
        this.getTransactionsListData(page)
    }
    // Customizable Area End
}

