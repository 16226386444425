import React, { CSSProperties } from "react";
// Customizable Area Start
import PlanTransactionsController, {
    Props
} from "./PlanTransactionsController.web";
import { rejectImg, pendingImg, activeImg, acceptImg, exportImg } from './assets';
import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import {
    Box,
    Typography,
    Button,
    TableContainer,
    TextField,
    Paper,
    Menu,
    IconButton,
    Select,
    InputLabel,
} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import Pagination from "@material-ui/lab/Pagination";
const languageTranslationData = require('../../languagesupport/src/LanguageData/PlanTransactions.json');
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import Text from "../../../components/src/design-system/Text";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    publishButton: {
        borderRadius: "25px",
        border: "1px solid #BFDBFE",
        backgroundColor: "#EFF6FF",
        padding: "8px 16px",
        cursor: "pointer",
        color: "#1D4ED8",
        fontSize: "16px",
        fontWeight: 700
    },
    draftButton: {
        padding: "8px",
        cursor: "pointer",
        color: "#94A3B8",
        fontSize: "16px",
        fontWeight: 600
    }
    // Customizable Area End
}

// Customizable Area Start

const detailPreview = {
    time: "54",
    portfolioSize: "2,500",
    listingTitle: "Medical Debt Package",
    averageBalance: "500",
    accountCount: "1",
    totalNumber: "1",
    totalAmount: "2,500",
    totalJudgement: "0",
    totalMonth: "3",
    pastPay: "Yes",
    monthAvg: "1",
    lastCollDay: "01",
    lastCollMonth: "02",
    lastCollYear: "2024",
    lastCredDay: "01",
    lastCredMonth: "02",
    lastCredYear: "2024",
    typeOfDebt: "Credit Card",
    ageOfDebt: "1",
    recoveryStatus: "ACTIVE COLLECTION"
};

const listingData = [
    ["#669677", "SarahCollins", "sarah.collins@debtagency.com", "Yearly", "Pending", "22 Feb 2022"],
    ["#669459", "MichaelThompson", "michael.thompson@debtagency.com", "SingleUse", "Successful", "21 Feb 2022"],
    ["#869287", "SarahCollins", "sarah.collins@debtagency.com", "Yearly", "Failed", "20 Feb 2022"],
    ["#664979", "MichaelThompson", "michael.thompson@debtagency.com", "SingleUse", "Pending", "20 Feb 2022"],
    ["#679287", "EmilyDavis", "emily.davis@debtagency.com", "SingleUse", "Pending", "14 Feb 2022"],
    ["#664559", "MichaelThompson", "michael.thompson@debtagency.com", "SingleUse", "Pending", "14 Feb 2022"]    
];
// Customizable Area End

export class PlanTransactions extends PlanTransactionsController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTableHeading = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        const { tableHeading } = languageData;
        return (
            <CustomHeadingRow>
                <TableCell>{tableHeading.ID}</TableCell>
                {
                   
                    <>
                        <TableCell>{tableHeading.UserName}</TableCell>
                        <TableCell>{tableHeading.Email}</TableCell>
                        <TableCell>{tableHeading.PlanType}</TableCell>
                        <TableCell>{tableHeading.Action}</TableCell>
                        <TableCell>{tableHeading.Date}</TableCell>
                        <TableCell></TableCell>
                    </>
                }
                
            </CustomHeadingRow>
        );
    };

    statusStyles = (value: string) => {
        let status: CSSProperties;
        if (value === "pending") {
            status = webStyle.pendingStatus;
        }
        else if (value === "failed") {
            status = webStyle.failedStatus;
        } 
        else {
            status = webStyle.successStatus
        }
        return status;
    }

    renderTableBodyRow = () => {
        return (
            <React.Fragment>
                {
                    <>
                        {this.state.transactions.map((transaction, index) => {
                            const { account, status, created_at, plan_type } = transaction.attributes
                            return (
                                <CustomBodyRow key={transaction.id}>
                                    <TableCell>{`#${transaction.id}`}</TableCell>
                                    <TableCell>{`${account.data.attributes.first_name || ""} ${account.data.attributes.last_name || ""}`}</TableCell>
                                    <TableCell>{account.data.attributes.email}</TableCell>
                                    <TableCell>{plan_type}</TableCell>
                                    <TableCell>
                                        <span style={this.statusStyles(status.toLowerCase())}>{status.toUpperCase()}</span></TableCell>
                                    <TableCell>{moment(created_at).format("DD MMM YYYY")}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            data-test-id="publishedMoreButtonTestID"
                                            data-state={JSON.stringify(transaction)}
                                            onClick={this.handlePublishMenu}
                                        >
                                            <MoreVertIcon style={webStyle.moreIconStyle} />
                                        </IconButton>
                                    </TableCell>
                                </CustomBodyRow>
                            )
                        })}
                    </>
                }
            </React.Fragment>
        );
    };

    renderTable = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        const { pagination } = this.state
        const results = (pagination.current_page - 1) * this.state.rowsPerPage;
        return (
            <>
                <TableUpBox>
                    <Box style={webStyle.searchFieldWrapper}>
                        <SearchIcon style={webStyle.searchIcon} />
                        <SearchTextField
                            data-test-id="searchInputTestID"
                            fullWidth
                            variant="outlined"
                            placeholder={languageData.Search}
                            value={this.state.searchText}
                            onChange={this.handleSearchChange}
                        />
                    </Box>
                    <Box>
                        <FilterButton
                            style={{ height: "44px", margin: 5 }}
                            data-testid="filterButtonTestID"
                            onClick={this.handleToggleFilter}
                        >
                            <Box style={webStyle.createButton}>
                                <FilterListRoundedIcon />
                                {languageData.Filters}
                            </Box>
                        </FilterButton>
                        <CreateButton
                            style={{ height: "44px", margin: 5 }}
                            data-testid="exportTransactionTestID"
                            onClick={this.handleExportTransactions}
                        >
                            <Box style={webStyle.createButton}>
                                <img src={exportImg} style={{ marginTop: -4 }} />
                                {languageData.ExportTransactions}
                            </Box>
                        </CreateButton>
                    </Box>
                </TableUpBox>
                <TableContainer style={webStyle.tableConStyle} component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            {this.renderTableHeading()}
                        </TableHead>
                        {
                            this.state.isLoading ?
                            <TableBody>
                                {
                                    Array.from(Array(this.state.rowsPerPage)).map((item, index) => {
                                        return (
                                            <TableRow key={index} style={{ verticalAlign: "text-top" }}>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                            
                            </TableBody>
                            :
                            <TableBody>
                                {this.renderTableBodyRow()}
                                <CustomBodyRow>
                                    <TableCell colSpan={6}>
                                        <Box style={{display: "flex", justifyContent: "center"}}>
                                            <PaginationStyle
                                                data-test-id="paginationTestID"
                                                page={pagination.current_page}
                                                count={pagination.total_pages}
                                                onChange={this.handlePageChange}
                                                siblingCount={0}
                                                boundaryCount={1}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell colSpan={2} style={{textAlign: "right"}}>
                                        <CustomResultBox>
                                            <Text size="xs" weight="minBold" variant="neutrals">
                                            {`${results + 1} - ${results + this.state.rowsPerPage > pagination.total_count ? pagination.total_count : results + this.state.rowsPerPage} of ${pagination.total_count} results`}
                                            </Text>
                                        </CustomResultBox>
                                    </TableCell>
                                </CustomBodyRow>
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </>
        );
    };

    renderListing = () => {
        const { currentLanguage } = this.state
        const languageData = languageTranslationData[currentLanguage];

        return (
            <Box style={webStyle.mainListingBox} data-test-id="listingMainBoxTestID">
                {
                    <Box style={webStyle.countWrapper}>
                        {[
                            {
                                icon: <img src={acceptImg} alt="activeIcon" width="24px" />,
                                title: languageData.TotalTransactions,
                                value: this.state.transactionDashboard.total
                            },
                            {
                                icon: <img src={activeImg} alt="activeIcon" width="24px" />,
                                title: languageData.TotalRevenue,
                                value: this.state.transactionDashboard.total_revenue
                            },
                            {
                                icon: <img src={pendingImg} alt="activeIcon" width="24px" />,
                                title: languageData.Pending,
                                value: this.state.transactionDashboard.pending
                            },
                            {
                                icon: <img src={rejectImg} alt="activeIcon" width="24px" />,
                                title: languageData.FailedTransactions,
                                value: this.state.transactionDashboard.failed
                            }
                        ].map((item, index) =>
                            <Box key={index} style={webStyle.countBox}>
                                {item.icon}
                                <Typography style={webStyle.countTitle}>{item.title}</Typography>
                                <Typography style={webStyle.countValue}>{item.value}</Typography>
                            </Box>
                        )}
                    </Box>
                }
                <TableBoxWrapper data-test-id="tableTestID" style={webStyle.tableBox}>
                    {this.renderTable()}
                </TableBoxWrapper>
            </Box>
        );
    };

    renderMenu = () => {
        const { currentLanguage } = this.state
        const languageData = languageTranslationData[currentLanguage];
        const stateObject = JSON.parse(this.state.publishAnchorEl?.dataset?.state || "{}")
        console.log("Manoj renderMenu", stateObject)
        return (
            <Menu
                open={Boolean(this.state.publishAnchorEl)}
                id="basic-menu"
                data-testid="viewDetailsTestID"
                anchorEl={this.state.publishAnchorEl}
                onClose={this.handleMenuClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        overflow: 'visible',
                        boxShadow: '0px 0px 8px 4px #00000008',
                        width: "160px",
                        borderRadius: "8px",
                        border: "1px solid #E2E8F0"
                    }
                }}
            >
                <PublisMenuBox>
                    <Typography data-testid="viewDetails" style={{ color: "#0F172A", fontSize: "14px" }}
                        onClick={this.handleDetailPreviewShow}
                    >
                        <Link to={{
                            pathname: `/dashboard/PlanTransactions/${stateObject?.id || ""}`,
                            state: stateObject
                        }}
                        style={{ textDecoration: "none", color: "rgb(15, 23, 42)" }}
                        >
                            {languageData.ViewDetails}
                        </Link>
                    </Typography>
                    <Typography style={{ color: "#0F172A", fontSize: "14px" }}>
                        {languageData.Refund}
                    </Typography>
                </PublisMenuBox>
            </Menu>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <UserProfileDrawer
                data-test-id="sideDrawerTestID"
                handleClick={this.handleDrawer}
                pageName="PlanTransactions"
                breadScrumArray={this.state.breadscrumarray}
                userType = {"admin_account"}
                navigation={this.props.navigation}
            >

                <Box>
                    <Box style={{ marginBottom: "16px" }}>
                        <Typography style={webStyle.pageHeadingText}>
                            {this.state.pageHeadingText}
                        </Typography>
                    </Box>
                    {this.renderListing()}
                </Box>
                {this.renderMenu()}
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

export default withStyles(styles)(PlanTransactions);

// Customizable Area Start

const TableBoxWrapper = styled(Box)({
    "@media(max-width: 1250px)": {
        width: "850px"
    },
    "@media(max-width: 1200px)": {
        width: "800px"
    },
    "@media(max-width: 1150px)": {
        width: "750px"
    },
    "@media(max-width: 1100px)": {
        width: "700px"
    },
    "@media(max-width: 1050px)": {
        width: "650px"
    },
    "@media(max-width: 1000px)": {
        width: "600px"
    },
    "@media(max-width: 990px)": {
        width: "auto"
    }
});

const PaginationStyle = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        fontSize: "12px",
        minWidth: "32px",
        height: "32px",
        color: "#0F172A",
        textDecoration: "underline",
        backgroundColor: "transparent",
        "@media(max-width: 421px)": {
            margin: "0px"
        },
        "&:hover": {
            backgroundColor: "#f0f0f0"
        }
    },
    "& .Mui-selected": {
        color: "#1D4ED8 !important",
        fontWeight: 700,
        backgroundColor: "transparent !important",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#f0f0f0 !important"
        }
    },
    "& .MuiPaginationItem-ellipsis": {
        fontSize: "12px",
        textDecoration: "none"
    }
});

const CustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width:"100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});

const ResetButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "44px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});

const FilterResetWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
        textTransform: "capitalize",
        color: "#1E3A8A",
        fontSize: "14px",
        fontWeight: 700
    }
});

const FilterSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    height: "44px",
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    }
});

const FilterInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    top: "-5px",
    "&.Mui-focused": {
        display: "none"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const PublisMenuBox = styled(Box)({
    padding: "0 4px",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    "& .MuiTypography-root": {
        padding: "4px 10px",
        cursor: "pointer",
        color: "#0F172A",
        fontSize: "14px",
        borderRadius: "8px",
        "&:hover": {
            background: "#EFF6FF"
        }
    },
    "& .MuiBox-root": {
        padding: "0 6px",
        cursor: "pointer",
        display: "flex",
        borderRadius: "8px",
        "&:hover": {
            background: "#EFF6FF"
        }
    }
});

const SearchTextField = styled(TextField)({
    flexGrow: 1,
    maxWidth: "320px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const FilterDateField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            top: "0px"
        }
    },
    "& .MuiInputBase-input": {
        height: "10px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        top: "0px",
        "&.Mui-disabled": {
            WebkitTextFillColor: "#0F172A"
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const FilterButton = styled(Button)({
    fontSize: "14px",
    fontWeight: 600,
    color: "#0F172A",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content"
});

const CreateButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});

const CustomHeadingRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});

const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#1E293B",
        fontSize: "12px",
        fontWeight: 400
    }
});

const TableUpBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    gap: "20px",
    flexWrap: "wrap"
});

const webStyle = {
    modalSelectItem: {
        minWidth: "350px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 20px",
        borderRadius: "8px",
        border: "1px solid #3B82F6",
        cursor: "pointer"
    },
    modalBody: {
        background: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px 4px #00000008",
        maxWidth: "580px"
    },
    modCloseBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems:  "center",
        marginBottom: "25px",
        padding: "0 32px"
    },
    modalBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
    filterHeadingBox: {
        fontSize: "14px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        color: "#0F172A",
        gap: "8px"
    },
    filterLabel: {
        fontWeight: 700,
        fontSize: "14px",
        color: "#334155"
    },
    filterBottomBox: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        padding: "20px"
    },
    filterMiddleBox: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        padding: "10px 20px 20px",
        borderBottom: "1px solid #CBD5E1"
    } as React.CSSProperties,
    filterCloseBox: {
        padding: "5px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #CBD5E1",
        alignItems: "center"
    },
    filterWrapper: {
        width: "350px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        position: "absolute",
        top: "56px",
        background: "white",
        zIndex: 10,
        right: "0px"
    } as React.CSSProperties,
    edit: {
        fontWeight: 700,
        color: "#1E3A8A",
        fontSize: "16px"
    },
    iconEdit: {
        width: "15px",
        color: "#1E3A8A"
    },
    editMainWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer"
    },
    mainListingBox: {
        display: "flex",
        flexDirection: "column",
        gap: "28px",
        marginTop: "25px"
    } as React.CSSProperties,
    tableConStyle: {
        boxShadow: "none",
        width: "100%",
        overflow: "auto",
        borderRadius: "16px",
    },
    tableBox: {
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        marginTop: "8px",
        background: "white"
    },
    moreIconStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    pendingStatus: {
        borderRadius: "40px",
        lineHeight: "18px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEF3C7",
        color: "#D97706",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
    } as React.CSSProperties,
    successStatus: {
        borderRadius: "40px",
        lineHeight: "18px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#D1FAE5",
        color: "#059669",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        padding: "0 10px"
    } as React.CSSProperties,
    failedStatus: {
        borderRadius: "40px",
        lineHeight: "18px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEE2E2",
        color: "#DC2626",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
    } as React.CSSProperties,
    statusStyling: {
        borderRadius: "40px",
        lineHeight: "18px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEF3C7",
        color: "#D97706",
        textTransform: "uppercase"
    } as React.CSSProperties,
    searchFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "23px",
        maxWidth: "400px"
    },
    searchIcon: {
        color: "#94A3B8"
    },
    countWrapper: {
        display: "flex",
        gap: "15px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    countBox: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        borderRadius: "20px",
        border: "1px solid #E2E8F0",
        padding: "20px",
        width: "160px",
        background: "white"
    } as React.CSSProperties,
    countTitle: {
        fontSize: "18px",
        fontWeight: 500,
        color: "#94A3B8",
        marginTop: "18px"
    },
    countValue: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#0F172A"
    },
    createButton: {
        display: 'flex',
        gap: "10px"
    },
    pageHeadingText: {
        fontSize: "30px",
        fontWeight: 700,
        color: "#0F172A"
    },
    blankMainBox: {
        height: "460px",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    },
    blankBodyWrapper: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        gap: "20px"
    } as React.CSSProperties,
    blankParagraph: {
        textAlign: 'center',
        fontSize: "18px",
        fontWeight: 500
    } as React.CSSProperties,
}
// Customizable Area End