// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import Footer from "../../components/src/design-system/Footer/Footer";
import PrivacyPolicy from "../../components/src/PrivacyPolicy.web"



import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfworkflowautomation4 from "../../blocks/cfworkflowautomation4/src/Cfworkflowautomation4";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Cfsadadpaymnent2 from "../../blocks/cfsadadpaymnent2/src/Cfsadadpaymnent2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/ResetForgotPassword.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Sorting from "../../blocks/sorting/src/Sorting";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfcatalogue from "../../blocks/cfcatalogue/src/Cfcatalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Cfrebrandredesign from "../../blocks/cfrebrandredesign/src/Cfrebrandredesign";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import Cfpenaltyfeecalculator from "../../blocks/cfpenaltyfeecalculator/src/Cfpenaltyfeecalculator";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cflandingpage4 from "../../blocks/cflandingpage4/src/Cflandingpage4";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cf3rdpartydeliveryintegration from "../../blocks/cf3rdpartydeliveryintegration/src/Cf3rdpartydeliveryintegration";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Proposalgeneration2 from "../../blocks/proposalgeneration2/src/Proposalgeneration2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfwhatsapp15 from "../../blocks/cfwhatsapp15/src/Cfwhatsapp15";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Analytics from "../../blocks/analytics/src/Analytics";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Inventorymanagement3 from "../../blocks/inventorymanagement3/src/Inventorymanagement3";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import IndustrialPageWeb from "../../blocks/landingpage/src/IndustrialPage.web";
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web";
import UserLoginWeb from '../../blocks/email-account-login/src/UserLogin.web'
import SellerLoginWeb from '../../blocks/email-account-login/src/SellerLogin.web'
import ServiceInternalTypeWeb from '../../blocks/email-account-login/src/ServiceInternalType.web'
import UserProfileWeb from '../../blocks/email-account-login/src/UserProfile.web'
import ListingSummary from "../../blocks/email-account-login/src/ListingSummary.web"
import SellerRegistration from '../../blocks/email-account-registration/src/SellerRegistration.web'
import Service from "../../blocks/productdescription/src/ServiceType.web";
import ServiceInternalForm from "../../blocks/productdescription/src/ServiceInternalForm.web"
import RequestsWeb from "../../blocks/email-account-login/src/Request.web"
import ApplicationSubmitted from "../../blocks/productdescription/src/ApplicationSummited.web";
import CreditoreWeb from "../../blocks/contentmanagement/src/Creditore.web"
import SubmitRfq from "../../blocks/productdescription/src/SubmitRfq.web";
import ServiceInternalNotLoggedIn from "../../blocks/productdescription/src/ServiceInternalNotLoggedIn.web";
import ContentManagement from "../../blocks/contentmanagement/src/ContentManagement.web";
import DebtBuyer from "../../blocks/contentmanagement/src/DebtBuyer.web";
import ServiceContact from "../../blocks/productdescription/src/ServiceContact.web";
import PasswordResetSuccess from "../../blocks/forgot-password/src/NewPassword.web";
import VerificationRequest from "../../blocks/productdescription/src/VerificationRequest.web"
import SuperAdminLogin from "../../blocks/email-account-login/src/SuperAdminLogin.web";
import SuperAdminForgotPassword from "../../blocks/forgot-password/src/SuperAdminForgotPassword.web";
import Dashboard from "../../blocks/adminconsole2/src/Dashboard.web"
import AdminDashboard from "../../blocks/adminconsole2/src/AdminDashboard.web"
import SuperAdminOtp from "../../blocks/forgot-password/src/SuperAdminOtp.web";
import SuperAdminResetPassword from "../../blocks/forgot-password/src/SuperAdminResetPassword.web";
import UserProfile from "../../blocks/adminconsole2/src/UserProfile.web";
import AdminProfile from "../../blocks/adminconsole2/src/AdminProfile.web";
import UserManagement from "../../blocks/adminconsole2/src/UserManagement.web";
import RequestManagement from "../../blocks/adminconsole2/src/RequestManagement.web";
import VerificationRequestManagement from "../../blocks/adminconsole2/src/VerificationRequestManagement.web"
import Profile from "../../blocks/email-account-login/src/Profile.web"
import CreateListing from "../../blocks/email-account-login/src/CreateListing.web"
import Notification from "../../blocks/email-account-login/src/Notification.web"
import Request from "../../blocks/email-account-login/src/Requests.web"
import Requests from "../../blocks/email-account-login/src/Request.web"
import AllListing from "../../blocks/email-account-login/src/AllListing.web"
import MyOffers from "../../blocks/email-account-login/src/MyOffers.web"
import RequestForm from "../../blocks/adminconsole2/src/RequestForm.web";
import UserDashboard from "../../blocks/email-account-login/src/UserDashboard.web";
import BusinessProfile from "../../blocks/email-account-login/src/BusinessProfile.web";
import PlanTransactions from "../../blocks/adminconsole2/src/PlanTransactions.web"; 
import Collections from "../../blocks/adminconsole2/src/Collections.web";
import Documentation from "../../blocks/documentation/src/Documentation.web";
import DocumentationFolder from "../../blocks/documentation/src/DocumentationFolder.web";
import UserPlan from "../../blocks/customisableuserprofiles/src/UserPlan.web";
import OfferDetails from "../../components/src/OfferDetails.web"

const routeMap = {
  SuperAdminResetPassword: {
    component: SuperAdminResetPassword,
    path: "/SuperAdminResetPassword"
  },
  SuperAdminOtp: {
    component: SuperAdminOtp,
    path: "/SuperAdminOtp"
  },
  SuperAdminLogin: {
    component: SuperAdminLogin,
    path: "/SuperAdminLogin"
  },
  SuperAdminForgotPassword: {
    component: SuperAdminForgotPassword,
    path: "/SuperAdminForgotPassword"
  },
  dashboardUserProfile : {
    component : UserProfile,
    path : "/dashboard/UserProfile/:id",
    exact : true
  },
  dashboard: {
    component: AdminDashboard,
    path: '/dashboard',
    exact: true
  },
  dashboardAdminProfile: {
    component: AdminProfile,
    path: '/dashboard/AdminProfile',
    exact: true
  },
  dashboardUserManagement: {
    component: UserManagement,
    path: '/dashboard/UserManagement',
    exact: true
  },
  dashboardNotification: {
    component: Notification,
    path: "/dashboard/Notification",
    exact : true
  },
  dashboardRequests: {
    component: RequestManagement,
    path: '/dashboard/Requests',
    exact: true
  },
  dashboardRequestForm: {
    component: RequestForm,
    path: '/dashboard/RequestForm',
    exact: true
  },
    dashboardStaff: {
    component: Dashboard,
    path: '/dashboard/Staff',
    exact: true
  },
  dashboardAddService: {
    component: Dashboard,
    path: '/dashboard/AddService',
    exact: true
  },
  dashboardListing: {
    component: AllListing,
    path: "/dashboard/Listing",
    exact : true
  },
  Documentation: {
    component: Documentation,
    path: '/dashboard/Documentation',
    exact: true
  },
  DocumentationFolder: {
    component: DocumentationFolder,
    path: '/dashboard/DocumentationFolder/:id/:name',
    exact: true
  },
  dashboardCollections: {
    component: Collections,
    path: "/dashboard/Collections",
    exact : true
  },
  dashboardCreateListing: {
    component: CreateListing,
    path: "/dashboard/CreateListing",
    exact : true
  },
  dashboardVerificationRequest: {
    component: VerificationRequestManagement,
    path: '/dashboard/VerificationRequest',
    exact: true
  },
  dashboardSettings: {
    component: Dashboard,
    path: '/dashboard/Settings',
    exact: true
  },
  dashboardAuditlog: {
    component: Dashboard,
    path: '/dashboard/Auditlog',
    exact: true
  },
  dashboardArchive: {
    component: Dashboard,
    path: '/dashboard/Archive',
    exact: true
  },
  Cfworkflowautomation4: {
    component: Cfworkflowautomation4,
    path: "/Cfworkflowautomation4"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SellerRegistration: {
    component: SellerRegistration,
    path: "/SellerRegistration"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Cfsadadpaymnent2: {
    component: Cfsadadpaymnent2,
    path: "/Cfsadadpaymnent2"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Cfcatalogue: {
    component: Cfcatalogue,
    path: "/Cfcatalogue"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Cfrebrandredesign: {
    component: Cfrebrandredesign,
    path: "/Cfrebrandredesign"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },
  Splitpayments2: {
    component: Splitpayments2,
    path: "/Splitpayments2"
  },
  Cfpenaltyfeecalculator: {
    component: Cfpenaltyfeecalculator,
    path: "/Cfpenaltyfeecalculator"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Cflandingpage4: {
    component: Cflandingpage4,
    path: "/Cflandingpage4"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Cf3rdpartydeliveryintegration: {
    component: Cf3rdpartydeliveryintegration,
    path: "/Cf3rdpartydeliveryintegration"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Proposalgeneration2: {
    component: Proposalgeneration2,
    path: "/Proposalgeneration2"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Cfwhatsapp15: {
    component: Cfwhatsapp15,
    path: "/Cfwhatsapp15"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Inventorymanagement3: {
    component: Inventorymanagement3,
    path: "/Inventorymanagement3"
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: "/AutomaticRenewals"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  IndustrialPage: {
    component: IndustrialPageWeb,
    path: '/IndustrialPageWeb',
    exact: true
  },

  ProductDescription: {
    component: ProductDescription,
    path: "/ProductDescription/:id",
  },

  Service: {
    component: Service,
    path: "/Service",
  },
  VerificationRequest: {
    component: VerificationRequest,
    path: "/VerificationRequest",
  },
  ServiceInternalForm: {
    component: ServiceInternalForm,
    path: "/ServiceInternalForm",
  },
  UserLogin: {
    component: UserLoginWeb,
    path: "/UserLoginWeb",
  },
  SellerLogin: {
    component: SellerLoginWeb,
    path: "/SellerLoginWeb",
  },
  ServiceInternalTypeWeb: {
    component: ServiceInternalTypeWeb,
    path: "/ServiceInternalTypeWeb",
  },
  UserProfile: {
    component: Profile,
    path: "/User/Profile",
    exact : true
  },
  UserNotification: {
    component: Notification,
    path: "/User/Notification",
    exact : true
  },
  UserMyListing: {
    component: AllListing,
    path: "/User/MyListing",
    exact : true
  },
  dashboardAdminOffers: {
    component: MyOffers,
    path: "/dashboard/AdminOffers",
    exact : true
  },
  UserMyOffers: {
    component: MyOffers,
    path: "/User/MyOffers",
    exact : true
  },
  UserCreateListing: {
    component: CreateListing,
    path: "/User/CreateListing",
    exact : true
  },
  UserRequests: {
    component: Request,
    path: "/User/Requests",
    exact : true
  },
  UserRequest: {
    component: Requests,
    path: "/User/Request",
    exact : true
  },
  ListingSummary: {
    component: ListingSummary,
    path: "/ListingSummary/:id",
  },
  RequestsWeb: {
    component: RequestsWeb,
    path: "/RequestsWeb",
  },
  ApplicationSubmitted: {
    component: ApplicationSubmitted,
    path: "/ApplicationSubmitted"
  },
  CreditoreWeb: {
    component: CreditoreWeb,
    path: "/CreditoreWeb"
  },
  SubmitRfq: {
    component: SubmitRfq,
    path: "/SubmitRfq"
  },
  ServiceInternalNotLoggedIn: {
    component: ServiceInternalNotLoggedIn,
    path: "/ServiceInternalNotLoggedIn"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement/:blog_id"
  },
  DebtBuyer: {
    component: DebtBuyer,
    path: "/DebtBuyer"
  },
  ServiceContact: {
    component: ServiceContact,
    path: "/ServiceContact"
  },
  PasswordResetSuccess: {
    component: PasswordResetSuccess,
    path: "/PasswordResetSuccess"
  },
  UserDashboard: {
    component: UserDashboard,
    path: "/User/Dashboard"
  },
  BusinessProfile: {
    component: BusinessProfile,
    path: "/User/BusinessProfile"
  },
  PlanTransactions: {
    component: PlanTransactions,
    path: "/dashboard/PlanTransactions",
    exact: true
  },
    UserPlan: {
    component: UserPlan,
    path: "/User/Plan",
    exact: true
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/privacy"
  },
  TermsCondition: {
    component: PrivacyPolicy,
    path: "/terms"
  },
  OfferDetails: {
    component: OfferDetails,
    path: "/dashboard/PlanTransactions/:id"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: 'auto', width: '100vw' }}>
        {!(window.location.href).includes("dashboard") && <TopNav />}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        {(!(window.location.href).includes("UserLoginWeb") && !(window.location.href).includes("SellerLoginWeb"))
        && !(window.location.href).includes("EmailAccountRegistration")
        && !(window.location.href).includes("ForgotPassword")
        && !(window.location.href).includes("NewPassword")
        && !(window.location.href).includes("SellerRegistration")
        && !(window.location.href).includes("SuperAdminOtp")
        && !(window.location.href).includes("SuperAdminForgotPassword")
        && !(window.location.href).includes("SuperAdminResetPassword")
       && !(window.location.href).includes("SuperAdminLogin") && !(window.location.href).includes("dashboard") && <Footer />}
      </View>
    );
  }
}

export default App;
